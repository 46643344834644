import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { LuPlus } from "react-icons/lu";

export default function MyRequestHeader({ title, des, onCreateRequest }) {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  function handleRequest() {
    onCreateRequest(true);
  }

  return (
    <>
      <Box
        w="full"
        bg={{ base: '#1448F5', md: '#000' }}
        color="white"
        py='1rem'
      >
        <Flex
          justifyContent={{ base: "center", md: "space-between" }}  
          alignItems="center"
          flexDir={{ base: "column", md: "row" }}  
          position="relative"
          gap={{ base: "10px", md: "5px" }}  

        >
          <Box
           textAlign="center"
           position="relative"
           left={{base:'5px', md:'5%'}}
           w="full"
          >
            <Heading color="white" fontWeight="600" fontSize={{ base: '18px', md: '26px' }}>
              My Session Requests
            </Heading>
            <Text color="white" fontWeight="500" fontSize={{ base: '10px', md: '14px' }}>
              View and manage your Session Requests
            </Text>
          </Box>
            <Box marginLeft={{base:'10px', md:'auto'}} px="20px"  textAlign="center">
              <Button
                onClick={handleRequest}
                rightIcon={<LuPlus />}
                bg="#0033ff"
                _hover={{
                  bg: "#0033ff",
                  color: "white",
                  boxShadow: "0 0 3px black",
                }}
                color="#F2F2F2"
                rounded="5px"
                py="12px"
                px="24px"
                w={{ base: "100%", xl: "auto" }}
                fontSize="14px"
                fontWeight="600"
              >
                Create a Request
              </Button>
            </Box>
        </Flex>
      </Box>

      {/* <Link to="/">
        <Box
          display={{ base: "block", md: "none" }}
          w="full"
          px="24px"
          borderBottomWidth="1px"
          bg="dull_black"
          boxShadow="sm"
          color="white"
          py="6px"
        >
          back
        </Box>
      </Link> */}
    </>
  );
}
