import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useToast,
  useClipboard
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { CalendarIcon, TimeIcon } from "@chakra-ui/icons";
import { FaLocationArrow, FaRegCalendar } from "react-icons/fa6";
import { SERVICE_IMAGE_URL, USER_IMAGE_URL } from "../../../apiPaths";
import StdProfile from "../../ProfileDetails";
import { color } from "framer-motion";

export default function MySessionRequestList({ data, onModifyRequest, onAccept }) {
  const sessionRequest = data;
  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(`https://createwithbase.com/calendar?isemail=true&isconfirm=true&rid=${sessionRequest._id}`);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let name, profilePic;
  if(currentUser.roleName === "TUTOR") {
    name = sessionRequest?.user?.firstName + " " + sessionRequest?.user?.lastName;
    profilePic = sessionRequest?.user?.profilePic ? USER_IMAGE_URL + sessionRequest?.user?.profilePic : "https://avatars2.githubusercontent.com/u/37842853?v=4";
  } else {
    name = sessionRequest?.tutor?.firstName + " " + sessionRequest?.tutor?.lastName;
    profilePic = sessionRequest?.tutor?.profilePic ? USER_IMAGE_URL + sessionRequest?.tutor?.profilePic : "https://avatars2.githubusercontent.com/u/37842853?v=4";
  }

  const startDate = new Date(sessionRequest?.start);
  const endDate = new Date(sessionRequest?.end);
  const formattedDate = startDate.toLocaleDateString("en-GB");
  const startTime = startDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  });
  const endTime = endDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  });
  const formattedTime = `${startTime} - ${endTime}`;
  const image = SERVICE_IMAGE_URL + sessionRequest?.service?.image;
  const details = currentUser.roleName == "STUDENT" ? sessionRequest?.tutor : sessionRequest?.user;

  const { isOpen: isCancelOpen, onOpen: onCancelOpen, onClose: onCancelClose } = useDisclosure();
  const { isOpen: isImageOpen, onOpen: onImageOpen, onClose: onImageClose } = useDisclosure();

  const [showDetailModel, setShowDetailModel] = useState(false);

  function handleFormOpen(val) {
    setShowDetailModel(val);
  }

  function handleFormClose(val) {
    setShowDetailModel(val);
  }

  function handleModifyRequest(id) {
    onAccept(false)
    onModifyRequest(id);
  }

  const handleCopyLink = () => {
    onCopy(); // Copies the link to the clipboard

    // Show toast notification
    toast({
      title: 'Link copied!',
      description: 'The request link has been copied to your clipboard.',
      status: 'success',
      duration: 3000, // Duration of the toast in milliseconds
      isClosable: true,
    });
  };


  return (
    <>
      <Flex
        bgColor={"#000"}
        flexDirection={"column"}
        rounded="8px"
        p={{ base: 5, lg: 5 }}
        boxShadow={"md"}
        m={{ base: 2, lg: 1 }}>

        <Box>
          <Image
            objectFit={"cover"}
            src={image}
            alt="Booking Image"
            height={200}
            width={"100%"}
            backgroundSize="cover"
            crossOrigin="anonymous"
          />
        </Box>

        <Box>
          <Flex flexDirection="column" gap="6px" w="100%">
            <Text fontSize="16px" fontWeight="700" mt={3} color="white">
              {sessionRequest?.service?.name}
            </Text>

            <Flex flexDirection="row" gap="8px" paddingBottom="8px">
              <Image
                height="24px"
                width="24px"
                borderRadius="50%"
                src={profilePic}
                crossOrigin="anonymous"
                alt="Service Image"
                onClick={() => handleFormOpen(true)}
                cursor="pointer"
              />
              <Text fontSize="14px" fontWeight="600" color="white">
                {name}
              </Text>
            </Flex>

            <Box marginLeft="4px">
              <Flex
                fontSize="14px"
                fontWeight="500"
                display="flex"
                alignItems="center"
                gap="13px"
                pb="3px"
                color="white"
              >
                <FaRegCalendar fontSize={"15px"} /> {formattedDate}
              </Flex>
              <Flex
                fontSize="14px"
                fontWeight="500"
                display="flex"
                alignItems="center"
                gap="13px"
                pb="3px"
                color="white"
              >
                <TimeIcon fontSize={"15px"} />
                {formattedTime}
              </Flex>
              <Flex
                fontSize="14px"
                fontWeight="500"
                display="flex"
                alignItems="center"
                gap="13px"
                pb="3px"
                color="white"
              >
                <FaLocationArrow fontSize={"15px"} />
                {sessionRequest?.sessionLocation}
              </Flex>
            </Box>

            <Flex
              flexDir="row"
              gap={{ base: "3", lg: "3" }}
              mb={{ base: "30px", lg: "0px" }}
              mt={2}
              display="flex"
              justifyContent="space-between"
              color="white"
            >

            { 
              (
                (currentUser.roleName === "TUTOR" && data.requestedTo === "creative" && data.status === "Pending") || 
                (currentUser.roleName === "STUDENT" && data.requestedTo === "student" && data.status === "Pending")
              ) && (
                <Button
                  bg="#0033ff"
                  _hover={{
                    bg: "#0033ff",
                    color: "white",
                  }}
                  color="#F2F2F2"
                  rounded="5px"
                  py="12px"
                  px="40px"
                  w={{ base: "100%", lg: "auto" }}
                  fontSize="14px"
                  fontWeight="600"
                  cursor="pointer"
                  onClick={() => onAccept(sessionRequest._id, true)}
                >
                  Accept
                </Button>
              )
            }

            { 
              (
                (currentUser.roleName === "TUTOR" && data.requestedTo === "student") || 
                (currentUser.roleName === "STUDENT" && data.requestedTo === "creative")
              ) && (
                <Button
                  bg="#0033ff"
                  _hover={{
                    bg: "#0033ff",
                    color: "#F2F2F2"
                  }}
                  color="#F2F2F2"
                  rounded="5px"
                  py="12px"
                  px="40px"
                  w={{ base: "100%", xl: "auto" }}
                  fontSize="14px"
                  fontWeight="600"
                  cursor="default"
                >
                  {sessionRequest?.status ? sessionRequest?.status : "Pending"}
                </Button>
              )
            }
            
              <Button
                bg="selectbg"
                color="black"
                _hover={{
                  bg: "white",
                  border: "1px solid black",
                }}
                border="1px solid white"
                rounded="5px"
                py="12px"
                px="40px"
                w={{ base: "100%", lg: "auto" }}
                fontSize="14px"
                fontWeight="600"
                onClick={() => handleModifyRequest(sessionRequest._id)}
              >
                Modify
              </Button>
            </Flex>
            { 
              (
                (currentUser.roleName === "TUTOR" && data.requestedTo === "student") || 
                (currentUser.roleName === "STUDENT" && data.requestedTo === "creative")
              ) && (
                <Text
                  color="#0033ff"
                  fontWeight="bold"
                  onClick={handleCopyLink}
                  _hover={{
                    textDecoration: "underline",
                    color: "#0033ff",
                    cursor: "pointer", 
                  }}
                  fontSize="14px"
                  marginTop="5px"
                  textAlign="center" 
                  w="full"  
                >
                  Copy Request Link
                </Text>

              )
            }
          </Flex>
        </Box>
      </Flex>


      {/* Modal for Cancel */}
      <Modal isOpen={isCancelOpen} onClose={onCancelClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent
          bg="white"
          color="black"
          px={{ base: "15px", lg: "40px" }}>
          <ModalHeader textAlign="center">Cancel Feedback Session?</ModalHeader>
          <ModalFooter display="flex " flexDir="column" w="full" gap="3">
            <Button
              w="full"
              onClick={onCancelClose}
              color="white"
              _hover={{
                bg: "deletecolor",
              }}
              bg="deletecolor"
              rounded="40px"
              py="12px"
              px="40px"
              fontSize="14px"
              fontWeight="600">
              Cancel booking
            </Button>
            <Button
              onClick={onCancelClose}
              w="full"
              variant="ghost"
              color="black"
              _hover={{
                bg: "selectbg",
              }}
              bg="selectbg"
              rounded="40px"
              py="12px"
              px="40px"
              fontSize="14px"
              fontWeight="600">
              Go back
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {showDetailModel && (
        <StdProfile onFormClose={handleFormClose} details={details} />
      )}
    </>
  );
}
