import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCSPgSyHJcykpf47LBlGyd0OudUsneEezM",
    authDomain: "refund-ab.firebaseapp.com",
    projectId: "refund-ab",
    storageBucket: "refund-ab.firebasestorage.app",
    messagingSenderId: "619000418364",
    appId: "1:619000418364:web:2fc7efb74df5fa62247339",
    measurementId: "G-CLDHF223V0"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
