import { Route, Routes } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import PaymentModel from './Components/payment/PaymentModel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './app.css';
import Home from './pages/Home';
import ProtectedRoute from './ProtectedRoute';
import Layout, { protectedRoutes } from './Components/sharedComponent/Layout';
import SearchPage from './pages/SearchPage';
function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/payment" element={<PaymentModel />} />
        <Route path="/search/:q" element={<SearchPage />} />

        <Route element={<Layout />}>
          {protectedRoutes.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<ProtectedRoute>{element}</ProtectedRoute>}
            />
          ))}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
