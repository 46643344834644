import { useState } from "react";
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  InputGroup,
  InputRightElement,
  Box,
  useToast,
  Img,
  Text,
  FormLabel,
} from "@chakra-ui/react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";

import { API_BASE_URL } from "../apiPaths";
import bgImage from "../assests/images/home.png";
import logo from "../assests/logo.png";
import google from "../assests/images/google.svg";
import { signInWithGoogle } from "../services/auth";

const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const sessionRequestFormData = JSON.parse(
    localStorage.getItem("sessionRequestFormData")
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => setShow(!show);
  const users = [
    {
      email: "admin@admin.com",
      password: "admin123",
      role: "TUTOR",
    },
    {
      email: "user@user.com",
      password: "user123",
      role: "STUDENT",
    },
  ];
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm();
  const watchEmail = watch("email", "");
  const watchPassword = watch("password", "");

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!data.email) {
      setError("email", {
        type: "manual",
        message: "email not recognised",
      });
    }
    if (!data.password) {
      setError("password", {
        type: "manual",
        message: "password incorrect",
      });
    }

    try {
      const response = await fetch(`${API_BASE_URL}/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const jsonResponse = await response.json();

      if (!response.ok) {
        toast({
          title: `${jsonResponse.data.error}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error(jsonResponse.message);
      }

      localStorage.setItem("currentUser", JSON.stringify(jsonResponse.data));

      if (jsonResponse?.data?.roleName === "TUTOR")
        localStorage.setItem("selectedButton", "calendar");
      else localStorage.setItem("selectedButton", "dashboard");
      toast({
        title: "Login success",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      if (jsonResponse.data.roleName === "STUDENT") navigate("/tutor-search");
      else navigate("/calendar");

      if (sessionRequestFormData && sessionRequestFormData?.url) {
        navigate(sessionRequestFormData.url);
      } else {
        if (jsonResponse.data.roleName === "STUDENT") navigate("/tutor-search");
        else navigate("/calendar");
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignInWithGoogle = () => {
    signInWithGoogle();
  }

  const isLoginFormValid = () => {
    return !watchEmail || watchPassword.length < 6;
  };

  return (
    <Box
      position="relative"
      background={`url(${bgImage})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      height={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box color="#fff" fontWeight="600" width={"90%"} mx={"auto"} pt={"10"}>
        <Link to="/">
          <Img src={logo} w={{ base: "30%", lg: "10%" }} cursor="pointer" />
        </Link>
      </Box>  

      <Box display={"flex"} my={"auto"}>
        <Stack
          minW={{ base: "100%", md: "700px" }}
          spacing={12}
          marginLeft="auto"
        >
          <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            width={"100%"}
            maxWidth={{ base: "400px", md: "none" }}
            mx="auto"
            rounded={{ base: "", md: "lg" }}
            px={{ base: "10px", md: "140px" }}
            py={{ base: "10px", md: "60px" }}
          >
            <Stack align="center" mb="30px">
              <Heading
                fontSize={{ base: "28px", md: "33px" }}
                fontWeight="900"
                color="white"
              >
                Sign In
              </Heading>
              <Text color="white">Let’s Build Something Great</Text>
            </Stack>

            <VStack spacing={4} w="100%">
              <FormControl id="email">
                <FormLabel fontSize="14px" fontWeight="500" color="white">
                  E-mail
                </FormLabel>
                <Input
                  borderRadius="0"
                  borderColor={errors.email ? "#E1526C" : "#fff"}
                  boxShadow="none" 
                  _hover={{
                    borderColor: "#fff",
                  }}
                  border="2px solid #fff"
                  type="email"
                  py="25px"
                  color="#fff"
                  rounded={"10"}
                  placeholder="Enter your email"
                  {...register("email")}
                  _placeholder={{
                    color: "#fff",
                    fontSize: { base: "16px", md: "14px" },
                  }}
                  _focus={{
                    borderColor: "white", 
                    boxShadow: "none", 
                  }}
                />

                <Box color="#E1526C" fontSize="16px" mt="5px">
                  {errors.email && <p>{errors.email.message}</p>}
                </Box>
              </FormControl>
              <FormControl id="password">
                <FormLabel fontSize="14px" fontWeight="500" color="white">
                  Password
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    boxShadow="none" 
                    _hover={{
                      borderColor: "#fff",
                    }}
                    border="2px solid #fff"
                    borderColor={errors.password ? "#E1526C" : "#fff"}
                    py="25px"
                     color="#fff"
                    rounded={"10"}
                    type={show ? "text" : "password"}
                    placeholder="Enter your password"
                    {...register("password")}
                    _placeholder={{
                      color: "#fff",
                      fontSize: { base: "16px", md: "14px" },
                    }}
                    _focus={{
                      borderColor: "white", 
                      boxShadow: "none", 
                    }}
                  />
                  <InputRightElement>
                    <Box
                      size="sm"
                      rounded="md"
                      pt={"4"}
                      pr={"2"}
                      onClick={handleClick}
                    >
                      {!show ? (
                        <AiOutlineEyeInvisible size={18} color="#fff" />
                      ) : (
                        <AiOutlineEye size={18} color="#fff" />
                      )}
                    </Box>
                  </InputRightElement>
                </InputGroup>
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.password && <p>{errors.password.message}</p>}
                </Box>
              </FormControl>
            </VStack>
            <Stack direction="row" justify="end" w="100%" color="#fff">
              <Link color="#fff" fontWeight="400" style={{ fontSize: "14px" }}>
                Forgot password?
              </Link>
            </Stack>
            <VStack w="100%" mt="12px" direction="column" gap="15px">
              <Button
                bg={"#1448F5"}
                color="white"
                borderRadius="0"
                rounded="8px"
                w="100%"
                py="25px"
                fontSize="18px"
                fontWeight="500"
                type="submit"
                // isDisabled={isLoginFormValid()}
                isLoading={isLoading}
                _hover={{
                  bg: "#0D3BC2",
                }}
              >
                Log in
              </Button>
              <Button
                bg={"#1448F5"}
                color="white"
                borderRadius="0"
                rounded="8px"
                w="100%"
                py="25px"
                fontSize="18px"
                fontWeight="500"
                onClick={(e) => handleSignInWithGoogle(e)}
                _hover={{
                  bg: "#0D3BC2",
                }}
              >
                <Img src={google} w="8%" cursor="pointer" marginRight="5px"/>
                Log in with Google
              </Button>
              <Box
                color={"white"}
                fontSize={"14px"}
                textAlign={"center"}
                display="flex"
                justifyContent="center"
                style={{ width: "100%" }}
                fontWeight={"400"}
              >
                Don't have an account?
                <Link to="/register">
                  <Text
                    marginLeft="5px"
                    textDecoration="underline"
                    _hover={{ color: "blue" }}
                  >
                    Register
                  </Text>
                </Link>
              </Box>
            </VStack>
          </VStack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Login;
