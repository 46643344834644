import React, { useState, useEffect } from "react";
import { API_BASE_URL, SERVICE_IMAGE_URL, USER_IMAGE_URL } from "../apiPaths";
import bgImage from "../assests/images/home.png";
import {
  Box,
  Flex,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Img,
  Card,
  CardBody,
  Grid,
  Image,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../assests/logo.png";
import CustomBtn from "../Components/sharedComponent/CustomBtn";
import { FaStar } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import profileImage from '../assests/images/avatar.webp';


export default function Home() {
  const { q } = useParams();
  const [searchQuery, setSearchQuery] = useState(q || "");
  const [userData, setUserData] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const IsSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const image = USER_IMAGE_URL + '/' + profileImage;

  const fetchData = async (query) => {
    if (!query) return;
    setIsFetch(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/user/tutors/get?q=${query}`
      );
      const data = await response.json();
      setSearchResults(data?.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsFetch(false);
    }
  };

  useEffect(() => {
    fetchData(searchQuery);
  }, [searchQuery]);

  const handleSearchEnter = (event) => {
    if (event.key === "Enter" && searchQuery.trim()) {
      setSearchQuery(searchQuery.trim());
    }
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.roleName === "TUTOR") {
        localStorage.setItem("selectedButton", "calendar");
        navigate("/calendar");
      } else if (currentUser.roleName === "STUDENT") {
        localStorage.setItem("selectedButton", "dashboard");
        navigate("/tutor-search");
      }
    }

    document.body.style.backgroundColor = "#F2F2F2";
    document.body.style.color = "black";
  }, [currentUser, navigate]);

  return (
    <Box
      position="relative"
      background={`url(${bgImage})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="top"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      py="1rem"
      alignItems="center"
      overflowY="hidden"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        background="rgba(0, 8, 56, 0.5)"
        zIndex={0}
      />

      <Flex
        justify="space-between"
        align="center"
        mx="auto"
        w="90%"
        zIndex={1}
        mt={5}
      >
        <Link to="/">
          <Img src={logo} alt="Logo" h="2.5rem" cursor="pointer" />
        </Link>

        <Box display={{ base: "none", lg: "block" }}>
          <Box display="flex" gap="10px" alignItems="center">
            <InputGroup size="sm" width="400px">
              <Input
                type="text"
                placeholder="Search tutor"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleSearchEnter}
                background="#fff"
                padding="20px 8px"
                fontWeight="500"
                color="#000"
                border="none"
                rounded="5"
                _placeholder={{ color: "#000" }}
              />
              <InputRightElement pointerEvents="none" padding="20px">
                <Search2Icon color="blue" h={3} w={3} />
              </InputRightElement>
            </InputGroup>

            {!currentUser ? (
              <>
                <Link to="/register">
                  <CustomBtn title="Sign Up" isBg={false} pathname={pathname} />
                </Link>
                <Link to="/login">
                  <CustomBtn title="Login" isBg={true} pathname={pathname} />
                </Link>
              </>
            ) : (
              <Button
                bg="white"
                _hover={{ bg: "black", color: "white" }}
                color="blue"
                rounded="40px"
                py="10px"
                px="20px"
                mx="10px"
                border="1px solid #fff"
                fontSize="14px"
                fontWeight="600"
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                }}
              >
                LOG OUT
              </Button>
            )}
          </Box>
        </Box>
      </Flex>

      <Box textAlign="center" my="8">
        <InputGroup
          size="sm"
          width={{ base: "300px", lg: "400px" }}
          display={{ base: "block", lg: "none" }}
        >
          <Input
            type="text"
            placeholder="Search tutor"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleSearchEnter}
            background="#fff"
            padding="20px 8px"
            fontWeight="500"
            color="#000"
            border="none"
            rounded="5"
            _placeholder={{ color: "#000" }}
          />
          <InputRightElement pointerEvents="none" padding="20px">
            <Search2Icon color="blue" h={3} w={3} />
          </InputRightElement>
        </InputGroup>
        <Text color="white" zIndex="100" mt="3">
          Search results for{" "}
          <Text as="span" fontSize="18px" textDecoration="underline">
            {searchQuery}
          </Text>
        </Text>
      </Box>

      {isFetch ? (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="10"
        >
          <Spinner size="xl" color="#fff" />
        </Box>
      ) : (
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
          gap="6"
          w="90%"
        >
          {searchResults.length > 0 ? (
            searchResults.map((user, index) => (
              <Card
                key={index}
                maxW="sm"
                overflow="hidden"
                backgroundColor="black"
              >
                <Box
                  backgroundColor="black"
                  minHeight={{ base: "22rem", md: "28rem" }}
                  px={{ base: 2, md: 4 }}
                  position="relative"
                  boxShadow="lg"
                >
                  <Box
                    position={"absolute"}
                    right={{ base: 2, md: 3 }}
                    top={{ base: 2, md: 4 }}
                  >
                    <FaStar size={IsSmallScreen ? 10 : 20} />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    py={{ base: 2, md: 5 }}
                  >
                    <Box
                      fontWeight="600"
                      color="#0033FE"
                      fontSize={{ base: "12px", md: "16px" }}
                    >
                      {user.firstName} {user?.lastName}
                    </Box>
                    <Box
                      fontWeight="500"
                      color="white"
                      fontSize={{ base: "10px", md: "14px" }}
                    >
                      {user.description}
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Image
                        height={{ base: 90, md: 130 }}
                        width="100%"
                        objectFit="cover" 
                        src={
                          user?.profilePic
                            ? USER_IMAGE_URL + user.profilePic
                            : profileImage
                        }
                        alt="User Profile Image"
                        crossOrigin="anonymous"
                      />
                      <Heading
                        fontWeight="600"
                        color="white"
                        fontSize={{ base: "13px", md: "16px" }}
                        py={3}
                      >
                        {user?.services?.[0]?.description}
                      </Heading>
                    </Box>
                    <Box display={"flex"} gap={2}>
                      {user?.services?.map((item) => (
                        <Box key={item._id || item.name}>
                          <Box display={"flex"} flexDirection={"column"}>
                            <Image
                              height={{ base: 10, md: 20 }}
                              crossOrigin="anonymous"
                              objectFit="cover"
                              width={{ base: 40, md: 40 }}
                              src={SERVICE_IMAGE_URL + item?.image}
                              alt={item?.image}
                            />
                            <Heading
                              fontWeight={"700"}
                              fontSize={{ base: "8px", md: "10px" }}
                              maxW={"7rem"}
                              textAlign={"center"}
                              py={1}
                              color="white"
                            >
                              {item?.name}
                            </Heading>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      pt={4}
                      pb={3}
                      justifyContent={"space-between"}
                    >
                      <Box>
                        <Heading
                          fontSize={{ base: "1.5rem", md: "1.5rem" }}
                          color={"#0033FE"}
                        >
                          ${user?.services?.[0]?.rate}
                        </Heading>
                        <Heading
                          fontSize={{ base: "12px", md: "10px" }}
                          fontWeight={"400"}
                          color={"#0033FE"}
                        >
                          Per Hour
                        </Heading>
                      </Box>
                      <Button
                        py={{ base: 0, md: 1 }}
                        variant={"unStyled"}
                        as={Link}
                        bg="selectbg"
                        backgroundColor="#0033FE"
                        rounded="5px"
                        fontSize={{ base: "10px", md: "12px" }}
                        fontWeight="600"
                        color="white"
                      >
                        View availability
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Card>
            ))
          ) : (
            <Box textAlign="center" color="white">
              No results found for "{searchQuery}".
            </Box>
          )}
        </Grid>
      )}
    </Box>
  );
}