import { Box, Button, Heading } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import CreativeItem from '../Components/FromSidebar/Student/CreativesList/CreativeItem';
import CreativesListHeader from '../Components/Headers/CreativesListHeader';
import { API_BASE_URL } from '../apiPaths';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { IoIosArrowForward } from 'react-icons/io';
import { useMediaQuery } from 'react-responsive';
import AliceCarousel from 'react-alice-carousel';

export default function CreativesList() {
  const [userData, setUserData] = useState([]);
  const IsSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const swiperRef = useRef(null);
  useEffect(() => {
    async function fetchData() {
      try {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const response = await fetch(`${API_BASE_URL}/user/tutors/get?q=`, {
          headers: {
            'x-access-token': currentUser.token,
          },
        });
        const users = await response.json();
        setUserData(users.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);
  const handleNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  return (
    <>
      <CreativesListHeader />

      <Box w={'95%'} pt={30}>
        <Box w={'95%'} mx={'auto'}>
          <Heading pb={5}>Favorites</Heading>
          <Box position="relative" display={{ base: 'none', md: 'block' }}>
            <AliceCarousel
              className="creativeList"
              mouseTracking={false}
              items={userData?.map((user) => (
                <CreativeItem key={user.id} data={user} />
              ))}
              responsive={{
                0: { items: 1 },
                600: { items: 2 },
                1024: { items: 3, itemsFit: 'contain' },
              }}
              stagePadding={{ paddingLeft: 0, paddingRight: 0 }}
              infinite={false}
              disableDotsControls={true}
              renderNextButton={() => (
                <Button
                  variant="unStyled"
                  aria-label="Next"
                  position="absolute"
                  right={'-7rem'}
                  top="45%"
                  transform="translateY(-50%)"
                  zIndex={2}
                >
                  <IoIosArrowForward size={IsSmallScreen ? 40 : 80} />
                </Button>
              )}
            />
          </Box>
          <Box
            display={{ base: 'flex', md: 'none' }}
            justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}
          >
            <Swiper
              modules={[Navigation]}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              navigation={false}
              pagination={{ clickable: true }}
              spaceBetween={IsSmallScreen ? 10 : 10}
              slidesPerView={2}
              breakpoints={{
                600: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 5,
                },
              }}
            >
              {userData?.map((user) => (
                <SwiperSlide key={user._id}>
                  <CreativeItem data={user} />
                </SwiperSlide>
              ))}
            </Swiper>
            <Box
              onClick={handleNextClick}
              position="absolute"
              right={{ base: '-4px', md: '20px' }}
              top={{ lg: '70%' }}
              transform="translateY(-50%)"
              zIndex="10"
              cursor="pointer"
            >
              <IoIosArrowForward size={IsSmallScreen ? 40 : 80} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
