import React, { useEffect, useState } from "react";
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  InputGroup,
  Box,
  Textarea,
  FormLabel,
  Image,
  Flex,
  Select,
  FormErrorMessage,
  Text,
  useToast,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  FormHelperText,
  ModalBody,
  filter,
  useClipboard
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_BASE_URL } from "../../../apiPaths";
import { Link } from "react-router-dom";
import is from "date-fns/locale/is/index";

const signupSchema = yup.object().shape({
  start: yup.date().required("Start time is required"),
  end: yup
    .date()
    .required("End time is required")
    .min(yup.ref("start"), "End time can't be before start time"),
  service: yup.string().required("Service is required"),
  location: yup.string().required("Location is required"),
});

const RequestForm = ({
  onFormClose,
  fetchMyRequest,
  isCalenderRequest,
  startDate,
  endDate,
  tutorId,
  requestId,
  isAccept,
  onDeleteSuccess
}) => {
  
  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(`https://createwithbase.com/calendar?isemail=true&isconfirm=true&rid=${requestId}`);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const { creative, end, start, location, service } = JSON.parse(localStorage.getItem('sessionRequestFormData')) || {};
  const [tutors, setTutors] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(true);
  const { isOpen, onClose } = useDisclosure();
  const [isUserDisabled, setIsUserDisabled] = useState(false);
  const [isTutorDisabled, setIsTutorDisabled] = useState(false);
  const [isLoginForm, setLoginForm] = useState(false);
  const [selectedTutorName, setSelectedTutorName] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const [showDefaultService, setShowDefaultService] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [tutorDayOffTime, setTutorDayOffTime] = useState('');
  const [tutorAvailabilityMessage, setTutorAvailabilityMessage] = useState('');
  const [selectedTutor, setSelectedTutor] = useState({});

  const currentDate = new Date();
  const currentDateTime = new Date().toISOString().slice(0, 16);

  const Start = startDate ? new Date(startDate) : new Date(); 
  Start.setHours(12, 0, 0, 0); 

  const End = endDate ? new Date(endDate) : new Date();
  End.setHours(14, 0, 0, 0);

  const formatDateTimeLocal = (date) => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().slice(0, 16);
  }; 

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(signupSchema),
    defaultValues: {
      start: formatDateTimeLocal(Start),
      end: formatDateTimeLocal(End),
      location: "Home Studio",
      service,
      creative
    },
  });

  const setFormValues = (data) => {   
    setValue("start", formatDateTimeLocal(new Date(data.start)));
    setValue("end", formatDateTimeLocal(new Date(data.end)));
    setValue("location", data.sessionLocation || "Home Studio");
    if(currentUser.roleName == "TUTOR") {
      setValue('service', data.service._id);      
      handleTutorChange(data?.tutor?._id)
      setIsTutorDisabled(true); 
      setIsUserDisabled(true); 

      if(data.requestedTo === 'creative' && data.tutor._id == tutorId) setSelectedUserName(data?.user?.firstName + " " + data?.user?.lastName)
      if(data.requestedTo === 'creative' && data.user._id === tutorId) setSelectedTutorName(data?.tutor?.firstName + " " + data?.tutor?.lastName)
      if(data.requestedTo === 'student' && data.tutor._id === tutorId) setSelectedUserName(data?.user?.firstName + " " + data?.user?.lastName)
      if(data.requestedTo === 'student' && data.user._id === tutorId) setSelectedTutorName(data?.tutor?.firstName + " " + data?.tutor?.lastName)
    } else {        
      setValue('service', data.service._id);  
      if(data.tutor._id === currentUser.id) {
        setSelectedTutorName(data?.user?.firstName + " " + data?.user?.lastName)
        handleTutorChange(data?.user?._id)
      }
      if(data.user._id === currentUser.id) {
        setSelectedTutorName(data?.tutor?.firstName + " " + data?.tutor?.lastName);
        handleTutorChange(data?.tutor?._id)
      }
    }
  };

  const sessionLocationOptions = [
    { value: "Home Studio", label: "Home Studio" },
    { value: "Zoom Meeting", label: "Zoom Meeting" },
    // ... other session locations
  ];

  function handleClose() {
    localStorage.removeItem('sessionRequestFormData');
    onFormClose(false);
  }

  const getTimeInMinutes = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return hours * 60 + minutes; // Convert time to total minutes
};

  const onSubmit = async (data) => {   
    let tutorEndTime;
    if(currentUser.roleName === 'TUTOR') tutorEndTime = currentUser.settings.latestFinishTime;
    else tutorEndTime = tutorDayOffTime;
    
    const endTime = new Date(data.end);
    
    const [tutorHour, tutorMinute] = tutorEndTime.split(":").map(Number);
    
    const tutorDayOffDate = new Date();
    tutorDayOffDate.setHours(tutorHour, tutorMinute, 0, 0); 
    const endTimeMinutes = getTimeInMinutes(endTime);
    const tutorDayOffMinutes = getTimeInMinutes(tutorDayOffDate);
    
    if (endTimeMinutes > tutorDayOffMinutes) {
      const startTimeFormatted = new Date(data.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const endTimeFormatted = new Date(data.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      
      setTutorAvailabilityMessage(
        `${selectedTutor.displayName} does not work before/later then ${startTimeFormatted} / ${endTimeFormatted}`
      );
      return;
    } 
        
    if(!currentUser) {
      data.url = window.location.pathname;;
      data.isRequestModelOpen = true;
      localStorage.setItem('sessionRequestFormData', JSON.stringify(data));
      setLoginForm(true);
      return;
    }

    if(requestId && tutorId){
      if(currentUser.roleName === "TUTOR") data.student = requestData.user._id;
      else data.creative = requestData.tutor._id;
    }    

    const method = requestId ? "PUT" : "POST"; 
    const url = requestId 
      ? `${API_BASE_URL}/session-request/${requestId}` 
      : `${API_BASE_URL}/session-request`; 
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentUser.token,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const responseData = await response.json();
        console.log(responseData.data);
        
        toast({
          title: responseData.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error("Network response was not ok");
      } else {
        if (!isCalenderRequest) fetchMyRequest();
        toast({
          title: requestId ? "Session request updated successfully." : "Session request send successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleClose();
      }
    } catch (error) {
      console.error("There has been a problem with your operation:", error);
    } finally {
    }
  };

  const handleBooking = async () => {
    const startDate = new Date(requestData.start);
    const endDate = new Date(requestData.end);
    const differenceInMilliseconds = endDate - startDate;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    const hours = Math.floor(differenceInHours);
    const minutes = Math.round((differenceInHours - hours) * 60);
    const formattedTime =
      `${hours} ${hours === 1 ? "hr" : "hrs"}` +
      (minutes !== 0 ? ` ${minutes} min` : "");

    const payload = {
      start: requestData.start,
      end: requestData.end,
      location: requestData.sessionLocation,
      service: requestData.service._id,
      repeat: "No Repeat",
      sessionOrBreak: "session",
      totalTime: formattedTime,
      id: requestData._id
    }
    
    if(currentUser.roleName === "TUTOR") payload.student = requestData.user._id;
    else payload.creative = requestData.tutor._id;    
    
    try {
      const response = await fetch(`${API_BASE_URL}/session-request/booking`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentUser.token,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        toast({
          title: "Request failed.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error("Network response was not ok");
      } else {
        if (!isCalenderRequest) fetchMyRequest();
        toast({
          title: "Session request booked successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleClose();
      }
    } catch (error) {
      console.error("There has been a problem with your operation:", error);
    } finally {
    }
  }

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/session-request/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentUser.token,
        },
      });

      if (!response.ok) {
        toast({
          title: "Error deleting session request.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error("Network response was not ok");
      } else {
        onDeleteSuccess();
        toast({
          title: "Session request deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleClose();
      }
    } catch (error) {
      console.error("Error deleting session request:", error);
    }
  };

  const handleTutorChange = async (tutorId) => {
    await getTutor(tutorId);
    const selectedTutor = tutors.find((tutor) => tutor._id === tutorId);    
    if (selectedTutor) {
      setShowDefaultService(true);
      setServices(selectedTutor.services);
      if(!requestId) setValue('service', selectedTutor?.services[0]?._id);
    } else {
      setServices([]);
    }
  };

  const handleStartChange = (value) => {
    // Calculate the new end time, which is 2 hours ahead of the start time
    const startDate = new Date(value);
    const endDate = new Date(startDate.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours
  
    const pad = (num) => num.toString().padStart(2, '0');
    const formattedEndDate = `${endDate.getFullYear()}-${pad(endDate.getMonth() + 1)}-${pad(endDate.getDate())}T${pad(endDate.getHours())}:${pad(endDate.getMinutes())}`;

    setValue("end", formattedEndDate);
  };

  useEffect(() => {
  
    async function fetchTutors() {
      try {
        const response = await fetch(`${API_BASE_URL}/user/tutors/get?q=`, {});
        const data = await response.json();
  
        if (currentUser.roleName === 'STUDENT' && requestId) tutorId = null;
  
        const filteredTutors = tutorId
          ? data.data.filter((tutor) => tutor._id === tutorId)
          : data.data;
  
        setTutors(filteredTutors);
  
        if (tutorId && filteredTutors.length > 0) {
          setTimeout(() => {
            setServices(filteredTutors[0].services);
            if (!requestId) setValue('service', filteredTutors[0].services[0]._id);
          }, 1000);
          if (!requestId) {
            setValue('creative', tutorId);
            setSelectedTutorName(`${filteredTutors[0].firstName} ${filteredTutors[0].lastName}`);
            handleTutorChange(tutorId);
            setIsTutorDisabled(true);
          }
        }
      } catch (error) {
        console.error("Error fetching tutors:", error);
      }
    }
  
    async function fetchStudents() {
      try {
        const response = await fetch(`${API_BASE_URL}/user/students/get`, {});
        const data = await response.json();
  
        setStudents(data.data);
      } catch (error) {
        console.error("Error fetching students:", error);
      } 
    }
  
    fetchTutors();
  
    if (currentUser) fetchStudents();
  }, [requestId]);

  async function getTutor(tutorId) {
    const response = await fetch(`${API_BASE_URL}/user/${tutorId}`, {
      headers: {
        'x-access-token': currentUser.token 
      }
    });
    const user = await response.json();      

    const minTimeString = user?.data?.tutorSettings?.earliestStartTime;   
    const maxTimeString = user?.data?.tutorSettings?.latestFinishTime; 
    setSelectedTutor(user.data)
    setTutorDayOffTime(maxTimeString)
    
 }

  useEffect(() => {    
    async function fetchSelectedRequest() {
      if (tutors.length > 0 && requestId) {
        try {
          const response = await fetch(`${API_BASE_URL}/session-request/get-by-id/${requestId}`, {
            headers: {
              'x-access-token': currentUser.token,
            },
          });
          const request = await response.json();
          if (request && request.data) {
            console.log(request.data);
            
            setFormValues(request.data);
            setRequestData(request.data);
            const tutorId = request.data?.tutor?._id;
            console.log(tutorId);
            
            if (tutorId) {
              await getTutor(tutorId);
            }
          }
        } catch (error) {
          console.error("Error fetching selected request:", error);
        }
      }
    }
  
    fetchSelectedRequest();
  }, [tutors, requestId]);
  

  const handleCopyLink = () => {
    onCopy(); // Copies the link to the clipboard

    // Show toast notification
    toast({
      title: 'Link copied!',
      description: 'The request link has been copied to your clipboard.',
      status: 'success',
      duration: 3000, // Duration of the toast in milliseconds
      isClosable: true,
    });
  };

  return (
    <>
      <Box>
        <Modal
          // sx={{ position: "fixed", left: "130px" }}
          blockScrollOnMount={false}
          isOpen={open}
          isCentered
          onClose={onClose}
          size="xl"
        >
          <ModalContent
            bg="black"
            color="white"
            my="auto"
            rounded="20px"
            border="2px solid #fff"
          >
            <ModalCloseButton
              position="absolute"
              top="10px"
              left="10px"
              color="#fff"
              onClick={() => {
                handleClose();
              }}
            />
            <ModalHeader
              textAlign="center"
              fontSize="24px"
              fontWeight="600" 
              bg="#1448F5" 
              rounded="20px 20px 0px 0px" 
              borderBottom="2px solid #fff"
            >
              {isAccept ? "Accept" : requestId ? "Modify" : "Create"} Session
              Request
            </ModalHeader>

            <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "20px" }}>
              {(requestId === null && isAccept === undefined) ||
              (requestId === null && isAccept === false) ? (
                <>
                  {currentUser?.roleName == "STUDENT" && (
                    <FormControl w="100%" mt="4" isInvalid={errors.creative}>
                      <FormLabel color="black">Creative</FormLabel>
                      <Controller
                        control={control}
                        name="creative"
                        render={({ field }) => (
                          <AutoComplete
                            openOnFocus
                            onSelectOption={(params) => {
                              const { value, label } = params.item;
                              field.onChange(value); // Update form value with selected ID
                              handleTutorChange(value); // Additional logic when an item is selected
                              setIsUserDisabled(value !== ""); // Disable user input if a value is selected
                              setSelectedTutorName(label);
                            }}
                          >
                            <AutoCompleteInput
                              variant="outlined"
                              color="#fff"
                              w="100%"
                              borderColor="gray.200"
                              backgroundColor="white"
                              borderWidth="1px"
                              placeholder="Select Creative"
                              disabled={isTutorDisabled}
                              _placeholder={{ color: "black" }}
                              value={selectedTutorName || field.value}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                field.onChange(newValue);
                                handleTutorChange(newValue);
                                newValue.length == 0
                                  ? setIsUserDisabled(false)
                                  : setIsUserDisabled(true);
                                setSelectedTutorName(newValue);
                              }}
                            />
                            <AutoCompleteList
                              bg="gray.100" // Grey background for the dropdown
                              borderColor="black"
                              pb="2"
                              pt="0"
                            >
                              {tutors?.map((tutor) => (
                                <AutoCompleteItem
                                  key={`option-${tutor._id}`}
                                  value={tutor._id}
                                  label={`${tutor.firstName} ${tutor.lastName}`}
                                  bg="white"
                                  color="black"
                                  mt="2"
                                  _hover={{ bg: "gray.200" }}
                                  isSelected={field.value === tutor._id}
                                >
                                  {tutor.firstName} {tutor.lastName}
                                </AutoCompleteItem>
                              ))}
                            </AutoCompleteList>
                          </AutoComplete>
                        )}
                      />
                      <FormErrorMessage>
                        {errors.creative?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}

                  {currentUser?.roleName == "TUTOR" && (
                    <FormControl w="100%" mt="4">
                      <FormLabel color="#fff">Student</FormLabel>
                      <Controller
                        control={control}
                        name="student"
                        render={({ field }) => (
                          <AutoComplete
                            openOnFocus
                            onSelectOption={(params) => {
                              const { value, label } = params.item;
                              field.onChange(value);
                              handleTutorChange(currentUser?.id);
                              setIsTutorDisabled(value !== "");
                              setSelectedUserName(label);
                            }}
                          >
                            <AutoCompleteInput
                              variant="outlined"
                              color="black"
                              w="100%"
                              borderColor="gray.200"
                              backgroundColor="white"
                              borderWidth="1px"
                              placeholder="Select Student"
                              disabled={isUserDisabled}
                              _placeholder={{ color: "black" }}
                              value={selectedUserName || field.value}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                field.onChange(newValue);
                                newValue.length == 0
                                  ? setIsTutorDisabled(false)
                                  : setIsTutorDisabled(true);
                                setSelectedUserName(newValue);
                              }}
                            />
                            <AutoCompleteList
                              bg="gray.100" // Grey background for the dropdown
                              borderColor="black"
                              pb="2"
                              pt="0"
                            >
                              {students?.map((student) => (
                                <AutoCompleteItem
                                  key={`option-${student?._id}`}
                                  label={`${student.firstName} ${student.lastName}`}
                                  value={`${student._id}`}
                                  bg="white"
                                  color="black"
                                  mt="2"
                                  _hover={{ bg: "gray.200" }}
                                >
                                  {student?.firstName} {student?.lastName}
                                </AutoCompleteItem>
                              ))}
                            </AutoCompleteList>
                          </AutoComplete>
                        )}
                      />
                    </FormControl>
                  )}
                </>
              ) : null}

              <Box display={{base:'block', md:'flex'}} gap="10px">
              <FormControl mt="4" isInvalid={errors.start}>
                <FormLabel color="#fff">Start</FormLabel>
                <Controller
                  name="start"
                  control={control}
                  rules={{
                    validate: (value) => {
                      const selectedTime = new Date(value).getHours();
                      const selectedDay = new Date(value).getDay();

                      // Load work hours from local storage
                      const workHours = JSON.parse(
                        localStorage.getItem("workHours")
                      ) || {
                        monFri: { start: 9, end: 17 },
                        sat: { start: 9, end: 17 },
                        sun: { start: 9, end: 17 },
                      };

                      let workingHours;
                      if (selectedDay >= 1 && selectedDay <= 5) {
                        // It's a weekday
                        workingHours = workHours.monFri;
                      } else if (selectedDay === 6) {
                        // It's Saturday
                        workingHours = workHours.sat;
                      } else {
                        // It's Sunday
                        workingHours = workHours.sun;
                      }

                      if (
                        selectedTime < workingHours.start ||
                        selectedTime > workingHours.end
                      ) {
                        return `Please select a time between ${workingHours.start}:00 and ${workingHours.end}:00`;
                      }
                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="datetime-local"
                      borderWidth="1px"
                      borderColor="gray.300"
                      _hover={{
                        borderWidth: "1px",
                        borderColor: "gray.300",
                      }}
                      color="#fff"
                      sx={{
                        "::-webkit-calendar-picker-indicator": {
                          filter: "invert(22%) sepia(45%) saturate(350%) hue-rotate(210deg) brightness(90%) contrast(120%)",
                          backgroundColor: "transparent", // Avoids a white square around the icon
                        },
                        
                      }}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        handleStartChange(e.target.value);
                      }}
                      min={currentDateTime}
                      isDisabled={isAccept}
                    />
                  )}
                />
                <FormErrorMessage>{errors.start?.message}</FormErrorMessage>
              </FormControl>

              <FormControl mt="4" isInvalid={errors.end}>
                <FormLabel color="#fff">End</FormLabel>
                <Controller
                  name="end"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="datetime-local"
                      borderWidth="1px"
                      color="#fff"
                      borderColor="gray.300"
                      _hover={{
                        borderWidth: "1px",
                        borderColor: "gray.300",
                      }}
                      sx={{
                        "::-webkit-calendar-picker-indicator": {
                          filter: "invert(22%) sepia(45%) saturate(350%) hue-rotate(210deg) brightness(90%) contrast(120%)",
                          backgroundColor: "transparent", // Avoids a white square around the icon
                        },
                      }}
                      {...field}
                      min={currentDateTime}
                      // max={maxEndDateTime}
                      isDisabled={isAccept}
                    />
                  )}
                />
                {tutorDayOffTime && tutorAvailabilityMessage && (
                  <Text color="red" fontSize="12px">
                    {tutorAvailabilityMessage}
                  </Text>
                )}
                <FormErrorMessage>{errors.end?.message}</FormErrorMessage>
              </FormControl>
              </Box>

              <FormControl mt="4" isInvalid={errors.service}>
                <FormLabel color="#fff">Specify a Service</FormLabel>
                <Controller
                  control={control}
                  name="service"
                  render={({ field }) => (
                    <Select
                      {...field}
                      borderWidth="1px"
                      borderColor="gray.300"
                      _hover={{
                        borderWidth: "1px",
                        borderColor: "gray.300",
                      }}
                      color="#fff"
                      isDisabled={isAccept}
                    >
                      {!showDefaultService && (
                        <option style={{ display: "block" }} value="">
                          Select Service
                        </option>
                      )}
                      {services?.map((service) => (
                        <option
                          style={{ backgroundColor: "white", color: "black" }}
                          key={service._id}
                          value={service._id}
                        >
                          {service.name}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                <FormErrorMessage>{errors.service?.message}</FormErrorMessage>
              </FormControl>

              <FormControl mt="4" isInvalid={errors.location}>
                <FormLabel color="#fff">Specify a Location</FormLabel>
                <Controller
                  control={control}
                  name="location"
                  render={({ field }) => (
                    <Select
                      {...field}
                      borderWidth="1px"
                      borderColor="gray.300"
                      _hover={{
                        borderWidth: "1px",
                        borderColor: "gray.300",
                      }}
                      color="#fff"
                      isDisabled={isAccept}
                    >
                      {sessionLocationOptions.map((option) => (
                        <option
                          style={{ backgroundColor: "white", color: "black" }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                <FormErrorMessage>{errors.location?.message}</FormErrorMessage>
              </FormControl>

              {!requestId ? (
                <Button
                  type="submit"
                  bg="#1448F5"
                  color="white"
                  fontWeight="bold"
                  rounded="8px"
                  w="full"
                  mt="8"
                  _hover={{
                    border: "1px solid black",
                    color: "#fff",
                    bg: "#1448F5",
                  }}
                >
                  Send Request
                </Button>
              ) : (
                <>
                  {isAccept ? (
                    <>
                      <Button
                        bg="#1448F5"
                        color="white"
                        fontWeight="bold"
                        rounded="8px"
                        w="full"
                        mt="8"
                        _hover={{
                          border: "1px solid black",
                          color: "#fff",
                          bg: "#1448F5",
                        }}
                        onClick={() => handleBooking()}
                      >
                        Confirm Booking
                      </Button>
                      <Button
                        type="button"
                        bg="red.500"
                        color="white"
                        fontWeight="bold"
                        rounded="8px"
                        w="full"
                        mt="4"
                        _hover={{
                          color: "#fff",
                          bg: "#E53E3E",
                        }}
                        onClick={() => handleDelete(requestId)}
                      >
                        Reject Request
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        bg="#1448F5"
                        color="white"
                        fontWeight="bold"
                        rounded="8px"
                        w="full"
                        mt="8"
                        _hover={{
                          border: "1px solid black",
                          color: "#fff",
                          bg: "#1448F5",
                        }}
                        type="submit"
                      >
                        Send New Request
                      </Button>
                      <Button
                        type="button"
                        bg="red.500"
                        color="white"
                        fontWeight="bold"
                        rounded="8px"
                        w="full"
                        mt="4"
                        _hover={{
                          color: "#fff",
                          bg: "#E53E3E",
                        }}
                        onClick={() => handleDelete(requestId)}
                      >
                        Delete Request
                      </Button>
                    </>
                  )}
                </>
              )}

              {!requestId && !isAccept && (
                <>
                  <Button
                    type="submit"
                    bg="#fff"
                    color="#000"
                    fontWeight="bold"
                    rounded="8px"
                    w="full"
                    mt="5"
                    onClick={handleCopyLink}
                    _hover={{
                      border: "1px solid black",
                      color: "black",
                      bg: "white",
                    }}
                  >
                    Copy Request Link
                  </Button>
                </>
              )}
            </form>
          </ModalContent>
        </Modal>
      </Box>

      {/* Login Modal */}
      <Modal
        isOpen={isLoginForm}
        onClose={() => setLoginForm(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg={"#fff"} color="#000">
          <ModalHeader>
            <Heading size="md">Authentication Required</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              Oops, it seems like you are not authenticated. Please authenticate
              yourself to complete the session request.
            </Text>
            <Flex
              direction="row"
              align="center"
              justify="space-between"
              px="5"
              mb="3"
            >
              <Button
                as={Link}
                to={`/login`}
                bg="selectbg"
                _hover={{
                  bg: "white",
                  color: "black",
                  border: "1px solid black",
                }}
                border="1px solid #f2f2f2"
                backgroundColor="black"
                rounded="40px"
                py="20px"
                px="40px"
                fontSize="14px"
                fontWeight="600"
              >
                Login
              </Button>
              <Button
                as={Link}
                to={`/register`}
                bg="selectbg"
                _hover={{
                  bg: "white",
                  color: "black",
                  border: "1px solid black",
                }}
                border="1px solid #f2f2f2"
                backgroundColor="black"
                rounded="40px"
                py="20px"
                px="40px"
                fontSize="14px"
                fontWeight="600"
              >
                Register
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestForm;
