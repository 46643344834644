import { Outlet } from 'react-router-dom';
import SidebarTwo from '../../pages/SidebarTwo';
import Dashboard from '../../pages/Dashboard';
import MyCalendar from '../Calender/Calender';
import CreativesList from '../../pages/CreativesList';
import NewService from '../../pages/NewService';
import MyBookings from '../FromSidebar/Student/MyBookings/MyBookings';
import MyRequest from '../../pages/MyRequest';
import MyBookingsTutor from '../FromSidebar/Tutor/MyBookings/MyBookingsTutor';
import BookSessionsForm from '../../pages/BookSessionForm';
import StdCalendar from '../Calender/StdCalendar/Calender';
import BookSessionFormOne from '../Forms/BookSession/Service/BookSessionFormOne';
import Profile from '../../pages/Profile';
import Setting from '../../pages/Setting';
import Services from '../../pages/Services';
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import SmallHeader from './SmallHeader.jsx';
const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      style={{
        display: 'flex',
        marginRight: 'auto',
        position: 'relative',
        overflowX: 'hidden',
        backgroundColor: 'rgb(46, 46, 46)'
      }}
    >
      <motion.div
        initial={{ x: isSmallScreen ? '-100%' : 0 }}
        animate={{
          x: isSmallScreen && isOpen ? '0%' : isSmallScreen ? '-100%' : 0,
        }}
        transition={{ type: 'tween', duration: 0.3 }}
        style={{
          position: isSmallScreen ? 'absolute' : 'fixed',
          zIndex: 1100,
          width: isSmallScreen ? '90%' : '16%',
          height: "100vh"
        }}
      >
        <SidebarTwo handleToggle={handleToggle} />
      </motion.div>
      {/* <div style={{ width: isSmallScreen ? '100%' : '84%' }}>
        {isSmallScreen && (
          <div>
            <SmallHeader handleToggle={handleToggle} />
          </div>
        )}
        <Outlet/>
      </div> */}
       <div
        style={{
          marginLeft: isSmallScreen ? '0' : '16%', 
          width: '100%',
          height: '100vh',
          overflowY: 'auto', 
        }}
      >
        {isSmallScreen && <SmallHeader handleToggle={handleToggle} />}
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;

export const protectedRoutes = [
  { path: '/tutor-search', element: <Dashboard /> },
  { path: '/calendar', element: <MyCalendar /> },
  { path: '/yourservices', element: <Services /> },
  { path: '/creativeslist', element: <CreativesList /> },
  { path: '/yourservices/new', element: <NewService /> },
  { path: '/mybookings', element: <MyBookings /> },
  { path: '/my-requests', element: <MyRequest /> },
  { path: '/upcomingsessions', element: <MyBookingsTutor /> },
  { path: '/book-session/:id', element: <BookSessionsForm /> },
  { path: '/calendar/tutor/:tutorId', element: <StdCalendar /> },
  {
    path: '/tutor/choose-service/:tutorId/:eventDate',
    element: <BookSessionFormOne />,
  },
  { path: '/profile', element: <Profile /> },
  { path: '/ratesetting', element: <Setting /> },
];
