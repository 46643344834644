import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { SERVICE_IMAGE_URL } from '../../../../apiPaths';
import StdProfile from '../../../ProfileDetails';
import { BUTTON_BG, BUTTON_COLOUR } from '../../../../constants/colours';
import { FaStar } from 'react-icons/fa6';
import { useMediaQuery } from 'react-responsive';

export default function CreativeItem(data) {
  const user = data.data;

  function handleAvaliablityBtnClick() {
    const element = document.querySelector('.css-hfafpj');
    if (element) {
      element.style.backgroundColor = BUTTON_BG;
      element.style.color = BUTTON_COLOUR;
    }
  }

  const [showDetailModel, setShowDetailModel] = useState(false);

  function handleFormOpen(val) {
    setShowDetailModel(val);
  }

  function handleFormClose(val) {
    setShowDetailModel(val);
  }
  console.log(data);
  const userData = data?.data;
  const userServices = data?.data?.services;
  const IsSmallScreen = useMediaQuery({ maxWidth: 1024 });
  return (
    <>
      <Box
        backgroundColor="black"
        minHeight={{ base: '22rem', md: '30rem' }}
        px={{ base: 2, md: 4 }}
        position="relative"
        boxShadow="lg"
      >
        <Box
          position={'absolute'}
          right={{ base: 2, md: 3 }}
          top={{ base: 2, md: 4 }}
        >
          <FaStar size={IsSmallScreen ? 10 : 20} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          py={{ base: 2, md: 5 }}
        >
          <Box
            fontWeight="600"
            color="#0033FE"
            fontSize={{ base: '12px', md: '16px' }}
          >
            {userData.firstName} {userData?.lastName}
          </Box>
          <Box
            fontWeight="500"
            color="white"
            fontSize={{ base: '10px', md: '14px' }}
          >
            {userData.description}
          </Box>
        </Box>
        <Box>
          <Box>
            <Image
              height={{ base: 90, md: 130 }}
              width="100%"
              objectFit="cover"
              src={SERVICE_IMAGE_URL + userServices[0]?.image}
              alt="Service Image"
              crossOrigin="anonymous"
            />
            <Heading
              fontWeight="600"
              color="white"
              fontSize={{ base: '13px', md: '16px' }}
              py={3}
            >
              {userServices[0]?.description}
            </Heading>
          </Box>
          <Box display={'flex'} gap={2}>
            {userServices?.map((item) => {
              return (
                <Box>
                  <Box display={'flex'} flexDirection={'column'}>
                    <Image
                      height={{ base: 10, md: 20 }}
                      crossOrigin="anonymous"
                      objectFit="cover"
                      width={{ base: 40, md: 40 }}
                      src={SERVICE_IMAGE_URL + item?.image}
                      alt={item?.image}
                    />
                    <Heading
                      fontWeight={'700'}
                      fontSize={{ base: '8px', md: '12px' }}
                      maxW={'7rem'}
                      textAlign={'center'}
                      py={1}
                    >
                      {item?.name}
                    </Heading>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            pt={4}
            pb={3}
            justifyContent={'space-between'}
          >
            <Box>
              <Heading
                fontSize={{ base: '1.5rem', md: '2rem' }}
                color={'#0033FE'}
              >
                $20
              </Heading>
              <Heading
                fontSize={{ base: '12px', md: '1rem' }}
                fontWeight={'400'}
                color={'#0033FE'}
              >
                Per Hour
              </Heading>
            </Box>
            <Button
              py={{ base: 0, md: 1 }}
              variant={'unStyled'}
              as={Link}
              to={`/calendar/tutor/${user._id}`}
              onClick={handleAvaliablityBtnClick}
              bg="selectbg"
              backgroundColor="#0033FE"
              rounded="5px"
              fontSize={{ base: '10px', md: '12px' }}
              fontWeight="600"
            >
              View availability
            </Button>
          </Box>
        </Box>
      </Box>

      {showDetailModel && (
        <StdProfile onFormClose={handleFormClose} details={user} />
      )}
    </>
  );
}
