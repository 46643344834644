import { Box } from '@chakra-ui/react';
import React from 'react';

const CustomBtn = ({ title, pathname, isBg }) => {
  return (
    <Box
      bg={isBg ? '#1448F5' : 'transparent'} 
      color={title === 'Login' ? 'white' : '#fff'} 
      rounded="5px"
      py={{ base: '8px', lg: '6px' }}
      px={title === 'Login' ? '50px' : '40px'}
      border={!isBg ? '1px solid #fff' : '1px solid #1448F5'} 
      fontSize="16px"
      fontWeight={{ base: '600', lg: '500' }}
      _hover={{
        bg: isBg ? '#1448F5' : 'transparent', 
        color: title === 'Sign Up' ? '#fff' : '#fff', 
      }}
      transition="all 0.3s"
    >
      {title}
    </Box>
  );
};

export default CustomBtn;
