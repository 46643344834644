export const NavLinks = [
  {
    path: '/calendar',
    title: 'Calendar',
  },
  {
    path: '/tutor-search',
    title: 'Tutor Search'
  },
  {
    path: '/creativeslist',
    title: 'Creatives'
  },
  {
    path: '/yourservices',
    title: 'My Services',
  },
  {
    path: '/upcomingsessions',
    title: 'My Bookings',
  },
  {
    path: '/my-requests',
    title: 'My Requests',
  },
  {
    path: '/ratesetting',
    title: 'Settings',
  },
];
