import React from "react";
import { useState } from "react";
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  InputGroup,
  Box,
  Img,
  Flex,
} from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import bgImage from "../../../assests/images/home.png";
import logo from "../../../assests/logo.png";

const signupSchema = yup.object().shape({
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  contact: yup.string().required("Contact is required"),
  address: yup.string().required("Address is required"),
});
const Register_Profile_One = ({ handleNext, handlePrevious, isLoading, setIsLoading, setFormData, activeStep, isTutor }) => {
  const [show, setShow] = useState(false);
  const [registerType, setRegisterType] = useState("TUTOR");
  
  const handleClick = () => setShow(!show);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(signupSchema) });

  // const watchEmail = watch("email", "");
  // const watchPassword = watch("password", "");
  // const watchConfirmpassword = watch("confirm_password", "");

  const onSubmit = async (data) => {
    setIsLoading(true);
    setFormData(prevData => ({ ...prevData, ...data }));
    setIsLoading(false);
    if(activeStep == 1 && !isTutor)  handleNext(true);
    else  handleNext(false);
    
  };

  return (
    <Box
      position="relative"
      background={`url(${bgImage})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      height={"100vh"}
      minHeight={"90%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box color="#fff" fontWeight="600" width={"90%"} mx={"auto"} pt={"10"}>
        <Link to="/">
          <Img src={logo} w={{ base: "30%", lg: "10%" }} cursor="pointer" />
        </Link>
      </Box>

      <Stack
        minW={{ base: "100%", md: "700px" }}
        spacing={12}
        marginLeft="auto"
        my="auto"
      >
        <VStack
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          width={"100%"}
          maxWidth={{ base: "400px", md: "none" }}
          mx="auto"
          rounded={{ base: "", md: "lg" }}
          px={{ base: "10px", md: "140px" }}
        >
          <Flex direction="column" align="center" width="100%">
            <Box display="flex" flexDirection="column" width="100%" mb="30px">
              <Box
                 onClick={() => {
                  handlePrevious();
                }}
                display="flex"
                alignItems="center"
                cursor="pointer"
                color="white"
                fontWeight="500"
                alignSelf="flex-start"
                position="relative"
                top="28px"
                left="0"
              >
                <IoIosArrowBack size="20px" />
                <Box>Back</Box>
              </Box>

              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "18px", md: "24px" }}
                  fontWeight="600"
                  color="white"
                >
                  Create an account
                </Heading>
                <Box color="#D2D2D2" fontSize="18px">
                  Your Profile {isTutor ? "1/3" : "1/2"}
                </Box>
              </Box>
            </Box>
          </Flex>
          <VStack spacing="12px" w="100%">
            <FormControl id="firstName">
              <Input
                borderColor={errors.firstName ? "#E1526C" : "#616161"}
                rounded="10px"
                color="#fff"
                type="text"
                py="25px"
                boxShadow="none"
                border="2px solid #fff"
                placeholder="First name"
                {...register("firstName")}
                _placeholder={{
                  color: "#fff",
                  fontSize: { base: "16px", md: "14px" },
                }}
                _focus={{
                  borderColor: "white",
                  boxShadow: "none",
                }}
                _hover={{
                  borderColor: "#fff",
                }}
              />
              <Box color="#E1526C" fontSize="14px" mt="5px">
                {errors.firstName && <p>{errors.firstName.message}</p>}
              </Box>
            </FormControl>
            <FormControl id="lastName">
              <Input
                borderColor={errors.lastName ? "#E1526C" : "#616161"}
                rounded="10px"
                py="25px"
                boxShadow="none"
                border="2px solid #fff"
                color="#fff"
                type="text"
                placeholder="Last name"
                {...register("lastName")}
                _placeholder={{
                  color: "#fff",
                  fontSize: { base: "16px", md: "14px" },
                }}
                _focus={{
                  borderColor: "white",
                  boxShadow: "none",
                }}
                _hover={{
                  borderColor: "#fff",
                }}
              />
              <Box color="#E1526C" fontSize="14px" mt="5px">
                {errors.lastName && <p>{errors.lastName.message}</p>}
              </Box>
            </FormControl>
            <FormControl id="contact">
              <Input
                borderColor={errors.contact ? "#E1526C" : "#616161"}
                rounded="10px"
                color="white"
                type="text"
                py="25px"
                boxShadow="none"
                border="2px solid #fff"
                placeholder="Contact number"
                {...register("contact")}
                _placeholder={{
                  color: "#fff",
                  fontSize: { base: "16px", md: "14px" },
                }}
                _focus={{
                  borderColor: "white",
                  boxShadow: "none",
                }}
                _hover={{
                  borderColor: "#fff",
                }}
              />
              <Box color="#E1526C" fontSize="14px" mt="5px">
                {errors.contact && <p>{errors.contact.message}</p>}
              </Box>
            </FormControl>

            <Box w="full" border="1px solid #fff" rounded="8px" p="12px">
              <FormControl id="address" flex="1">
                <InputGroup size="md">
                  <Input
                    borderColor={errors.address ? "#E1526C" : "#616161"}
                    color="white"
                    rounded="10px"
                    type="text"
                    py="25px"
                    boxShadow="none"
                    border="2px solid #fff"
                    placeholder="Address"
                    {...register("address")}
                    _placeholder={{
                      color: "#fff",
                      fontSize: { base: "16px", md: "14px" },
                    }}
                    _focus={{
                      borderColor: "white",
                      boxShadow: "none",
                    }}
                    _hover={{
                      borderColor: "#fff",
                    }}
                  />
                </InputGroup>
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.address && <p>{errors.address.message}</p>}
                </Box>
                <Button
                  mt="16px"
                  color="white"
                  bg={"#1448F5"}
                  borderRadius="0"
                  rounded="8px"
                  w="100%"
                  py="25px"
                  fontSize="18px"
                  fontWeight="500"
                  _hover={{
                    bg: "#0D3BC2",
                  }}
                  // type="submit"
                  // isLoading={isLoading}
                >
                  Find address
                </Button>
              </FormControl>
            </Box>
          </VStack>
          <VStack w="100%" mt={{ base: "15px", md: "30px" }}>
            <Button
              color="white"
              bg={"#1448F5"}
              borderRadius="0"
              rounded="8px"
              w="100%"
              py="25px"
              fontSize="18px"
              fontWeight="500"
              _hover={{
                bg: "#0D3BC2",
              }}
              type="submit"
              isLoading={isLoading}
            >
              Continue
            </Button>
          </VStack>
        </VStack>
      </Stack>
    </Box>
  );
};

export default Register_Profile_One;
