import { Button } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const NavItem = ({ path, title, pathname, onClick ,icon}) => {
  return (
    <Link to={path} onClick={onClick}>
      <Button
        my={'.4rem'}
        bg={pathname === path ? '#1448F5' : 'black'}
        color={'white'}
        rounded="6px"
        w="100%"
        py="20px"
        fontSize="16px"
        fontWeight="500"
        justifyContent="flex-start" 
        alignItems="center" 
        _hover={{
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          color: pathname === path ? '#fff' : '#1448F5'
        }}
      >
        <span style={{ fontSize: '16px', marginRight: '12px' }}>{icon}</span> 
        {title}
      </Button>
    </Link>
  );
};

export default NavItem;