import React from "react";
import { useState, useEffect } from "react";
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  Switch,
  InputGroup,
  Box,
  Textarea,
  FormLabel,
  Img,
  Link,
  Flex,
  Select,
  FormErrorMessage,
  useToast
} from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import bgImage from "../../../assests/images/home.png";
import logo from "../../../assests/logo.png";

const Register_Profile_Two = ({ handleNext, handlePrevious, isLoading, setIsLoading, setFormData }) => {
  const toast = useToast();
  const [show, setShow] = useState(false);

  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const handleSwitchChange = (e) => {
    setIsSwitchOn(e.target.checked);
  };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue
  } = useForm();

  const onSubmit = async (data) => {    
    setIsLoading(true);
    setFormData(prevData => ({ ...prevData, ...data }));
    setIsLoading(false);
    handleNext();
  };

  return (
    <Box
      position="relative"
      background={`url(${bgImage})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      height={"100vh"}
      minHeight={"90%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box color="#fff" fontWeight="600" width={"90%"} mx={"auto"} pt={"10"}>
        <Link to="/">
          <Img src={logo} w={{ base: "30%", lg: "10%" }} cursor="pointer" />
        </Link>
      </Box>

      <Stack
        minW={{ base: "100%", md: "700px" }}
        spacing={12}
        marginLeft="auto"
        my="auto"
      >
        <VStack
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          width={"100%"}
          maxWidth={{ base: "400px", md: "none" }}
          mx="auto"
          rounded={{ base: "", md: "lg" }}
          px={{ base: "10px", md: "140px" }}
        >
          <Flex direction="column" align="center" width="100%">
                      <Box display="flex" flexDirection="column" width="100%" mb="30px">
                        <Box
                           onClick={() => {
                            handlePrevious();
                          }}
                          display="flex"
                          alignItems="center"
                          cursor="pointer"
                          color="white"
                          fontWeight="500"
                          alignSelf="flex-start"
                          position="relative"
                          top="28px"
                          left="0"
                        >
                          <IoIosArrowBack size="20px" />
                          <Box>Back</Box>
                        </Box>
          
                        <Box textAlign="center">
                          <Heading
                            fontSize={{ base: "18px", md: "24px" }}
                            fontWeight="600"
                            color="white"
                          >
                            Create an account
                          </Heading>
                          <Box color="#D2D2D2" fontSize="18px">
                          Your Profile 2/3
                          </Box>
                        </Box>
                      </Box>
                    </Flex>
          <VStack spacing="12px" w="100%">
            <FormControl mt="4" isInvalid={errors.earliestStartTime}>
              <FormLabel color="#D2D2D2" fontSize="14px">
                Your Calendar Day Starts From
              </FormLabel>

              <Input
                type="time"
                {...register("earliestStartTime")}
                defaultValue={watch("earliestStartTime") || "09:00"}
                borderColor={errors.earliestStartTime ? "#E1526C" : "#616161"}
                rounded="10px"
                color="#fff"
                py="25px"
                boxShadow="none"
                border="2px solid #fff"
                _placeholder={{
                  color: "#fff",
                  fontSize: { base: "16px", md: "14px" },
                }}
                _focus={{
                  borderColor: "white",
                  boxShadow: "none",
                }}
                _hover={{
                  borderColor: "#fff",
                }}
              />
              <FormErrorMessage>
                {errors.earliestStartTime?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.latestFinishTime}>
              <FormLabel color="#D2D2D2" fontSize="14px">
                Your Calendar Day Finishes At
              </FormLabel>
              <Input
                type="time"
                {...register("latestFinishTime")}
                defaultValue={watch("latestFinishTime") || "17:00"}
                borderColor={errors.latestFinishTime ? "#E1526C" : "#616161"}
                rounded="10px"
                color="#fff"
                py="25px"
                boxShadow="none"
                border="2px solid #fff"
                _placeholder={{
                  color: "#fff",
                  fontSize: { base: "16px", md: "14px" },
                }}
                _focus={{
                  borderColor: "white",
                  boxShadow: "none",
                }}
                _hover={{
                  borderColor: "#fff",
                }}
              />
              <FormErrorMessage>
                {errors.latestFinishTime?.message}
              </FormErrorMessage>
            </FormControl>

            <VStack>
              <Heading
                fontSize={{ base: "18px", md: "24px" }}
                fontWeight="600"
                color="white"
                mt="20px"
              >
                Rate Setting
              </Heading>
            </VStack>

            <VStack spacing={{ base: "15px", md: "20px" }} w="100%">
              {/* <FormControl mt={4}>
                  <FormLabel color="#D2D2D2">Flat Rate</FormLabel>
                  <Switch
                    id='rate-setting'
                    size='lg'
                    onChange={handleSwitchChange}
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        color: 'grey',
                        '&.Mui-checked': {
                          color: 'blue ',
                        },
                      },
                    }}
                  />
                </FormControl> */}

              {/* {!isSwitchOn && ( */}
              <FormControl mt="4">
                <FormLabel color="#D2D2D2">Your flat rate per hour</FormLabel>
                <InputGroup gap="2" color="#D2D2D2">
                  <span className="euro-sign">£</span>
                  <Input
                    {...register("flatRate")}
                    defaultValue={watch("flatRate") || "0.0"}
                    className="rate-per-hour"
                    type="text"
                    rounded="10px"
                    color="#fff"
                    py="25px"
                    boxShadow="none"
                    border="2px solid #fff"
                    _placeholder={{
                      color: "#fff",
                      fontSize: { base: "16px", md: "14px" },
                    }}
                    _focus={{
                      borderColor: "white",
                      boxShadow: "none",
                    }}
                    _hover={{
                      borderColor: "#fff",
                    }}
                  />
                </InputGroup>
              </FormControl>
              {/* )} */}

              {/* {isSwitchOn && (
                  <>
                    <FormControl mt="4">
                      <FormLabel>Your rate for sessions under 1 hour</FormLabel>
                      <InputGroup gap="2">
                        <span className="euro-sign">£</span>
                        <Input
                          type="number"
                          borderWidth="1px"
                          borderColor="gray.300"
                          placeholder="per hour"
                          _placeholder={{
                            color: "text",
                            fontSize: { base: "12px", md: "14px" },
                          }}
                          _hover={{
                            borderWidth: "1px",
                            borderColor: "gray.300",
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl mt="4">
                      <FormLabel>Your rate for sessions 1 hour and over</FormLabel>
                      <InputGroup gap="2">
                        <span className="euro-sign">£</span>
                        <Input
                          type="number"
                          borderWidth="1px"
                          borderColor="gray.300"
                          placeholder="per hour"
                          _placeholder={{
                            color: "text",
                            fontSize: { base: "12px", md: "14px" },
                          }}
                          _hover={{
                            borderWidth: "1px",
                            borderColor: "gray.300",
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl mt="4">
                      <FormLabel>Your rate for sessions 2 hours and over</FormLabel>
                      <InputGroup gap="2">
                        <span className="euro-sign">£</span>
                        <Input
                          type="number"
                          borderWidth="1px"
                          borderColor="gray.300"
                          placeholder="per hour"
                          _placeholder={{
                            color: "text",
                            fontSize: { base: "12px", md: "14px" },
                          }}
                          _hover={{
                            borderWidth: "1px",
                            borderColor: "gray.300",
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl mt="4">
                      <FormLabel>Your rate for sessions 3 hours and over</FormLabel>
                      <InputGroup gap="2">
                        <span className="euro-sign">£</span>
                        <Input
                          type="number"
                          borderWidth="1px"
                          borderColor="gray.300"
                          placeholder="per hour"
                          _placeholder={{
                            color: "text",
                            fontSize: { base: "12px", md: "14px" },
                          }}
                          _hover={{
                            borderWidth: "1px",
                            borderColor: "gray.300",
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl mt="4">
                      <FormLabel>Your rate for sessions 4 hours and over</FormLabel>
                      <InputGroup gap="2">
                        <span className="euro-sign">£</span>
                        <Input
                          type="number"
                          borderWidth="1px"
                          borderColor="gray.300"
                          placeholder="per hour"
                          _placeholder={{
                            color: "text",
                            fontSize: { base: "12px", md: "14px" },
                          }}
                          _hover={{
                            borderWidth: "1px",
                            borderColor: "gray.300",
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                  </>
                )} */}
            </VStack>
          </VStack>
          <VStack w="100%" mt={{ base: "15px", md: "30px" }}>
            <Button
              color="white"
              bg={"#1448F5"}
              borderRadius="0"
              rounded="8px"
              w="100%"
              py="25px"
              fontSize="18px"
              fontWeight="500"
              _hover={{
                bg: "#0D3BC2",
              }}
              type="submit"
              isLoading={isLoading}
            >
              Continue
            </Button>
          </VStack>
        </VStack>
      </Stack>
    </Box>
  );
};

export default Register_Profile_Two;
