import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FiMenu } from "react-icons/fi";
import { LuPlus } from "react-icons/lu";
import { LuChevronLeft } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function SessionHeader({ handleNext, handlePrevious, formOne}) {
  const onBack = (data) => {
    handlePrevious();
  };

  return (
    <>
      <Box
        w="full"
        bg={{ base: '#1448F5', md: '#000' }}
        color="white"
        py="1rem"
      >
        <Flex
          justifyContent={{ base: "center", md: "space-between" }}  
          alignItems="center"
          flexDir={{ base: "column", md: "row" }}  
          position="relative"
          gap={{ base: "10px", md: "5px" }}  
        >
          {/* {!formOne && (
            <Link>
              <Button
                leftIcon={<LuChevronLeft />}
                color="black"
                py="6px"
                onClick={onBack}
                w="full"
                textAlign="left"
                display={{ base: "block", md: "none" }}  
              >
                Back
              </Button>
            </Link>
          )} */}

          <Box 
            textAlign="center"
            position="relative"
            left={{base:'5px', md:'5%'}}
            w="full"
          >
            <Heading color="white" fontWeight="600" fontSize="26px">
              Services
            </Heading>
            <Text color="white" fontWeight="500" fontSize={{ base: '10px', md: '14px' }}>
              Manage and Create the services you offer
            </Text>
          </Box>

          <Box marginLeft={{base:'10px', md:'auto'}} px="20px"  textAlign="center">
            <Button
              as={Link}
              to="/yourservices/new"
              rightIcon={<LuPlus />}
              bg="#0033ff"
              _hover={{
                bg: "white",
                color: "black",
                boxShadow: "0 0 3px black",
              }}
              color="#F2F2F2"
              rounded="5px"
              py={{ base: '10px', md: '12px' }}
              px={{ base: '10px', md: '24px' }}
              fontSize="14px"
              fontWeight="600"
            >
              Create a Service
            </Button>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
