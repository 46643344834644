import { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  InputGroup,
  InputRightElement,
  Box,
  Img,
  Text,
} from "@chakra-ui/react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import bgImage from "../../../assests/images/home.png";
import logo from "../../../assests/logo.png";
const signupSchema = yup.object().shape({
  email: yup.string().email("email is invalid").required("email is required"),
  password: yup
    .string()
    .min(6, "password must be at least 6 characters")
    .required("password is required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "passwords must match")
    .required("password confirmation is required"),
});
const RegisterDetails = ({
  handleNext,
  handlePrevious,
  isLoading,
  setIsLoading,
  setFormData,
  setIsTutor,
}) => {
  const [show, setShow] = useState(false);
  const [registerType, setRegisterType] = useState("TUTOR");

  const handleClick = () => setShow(!show);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(signupSchema) });

  const onSubmit = async (data) => {
    setIsLoading(true);
    data.registerType = registerType;
    setFormData(data);
    setIsLoading(false);
    registerType == "TUTOR" ? setIsTutor(true) : setIsTutor(false);
    handleNext();
  };

  return (
    <Box
      position="relative"
      background={`url(${bgImage})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box color="#fff" fontWeight="600" width={"90%"} mx={"auto"} pt={"10"}>
        <Link to="/">
          <Img src={logo} w={{ base: "30%", lg: "10%" }} cursor="pointer" />
        </Link>
      </Box>  
        <Stack minW={{ base: '100%', md: '700px' }}
          spacing={12}
          marginLeft="auto"
          my="auto"
          >
          <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            width={'100%'}
            maxWidth={{ base: '400px', md: 'none' }}
            mx="auto"
            rounded={{ base: '', md: 'lg' }}
            px={{ base: '10px', md: '140px' }}
          >
            <Stack align="center">
              <Heading fontSize={"33px"} fontWeight="700" color="white">
                Create an account
              </Heading>
              <Box color="white" fontSize={"14px"} fontWeight="400">
                Your Details
              </Box>
            </Stack>

            <Box w="100%" rounded="8px" my={"2"} display={"flex"}>
              <Button
                variant="unstyled"
                w={"100%"}
                py={"1.6rem"}
                px={"3rem"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bg={registerType === "student" ? "blue" : "black"}
                color={registerType === "student" ? "white" : "white"}
                rounded={
                  registerType === "student"
                    ? "10px 0px 0px 10px"
                    : "10px 0px 0px 10px"
                }
                transition="all"
                transitionDuration="0.5s"
                fontSize={"18px"}
                fontWeight={700}
                onClick={() => {
                  setRegisterType("student");
                }}
              >
                Student
              </Button>
              <Button
                w={"100%"}
                variant="unstyled"
                py={"1.6rem"}
                px={"3rem"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bg={registerType === "TUTOR" ? "#1448F5" : "black"}
                color={registerType === "TUTOR" ? "white" : "white"}
                rounded={
                  registerType === "TUTOR"
                    ? "0px 10px 10px 0px"
                    : "10px 0px 0px 10px"
                }
                transition="all"
                transitionDuration="0.5s"
                fontSize={"18px"}
                fontWeight={700}
                onClick={() => {
                  setRegisterType("TUTOR");
                }}
              >
                Tutor
              </Button>
            </Box>

            <VStack spacing="12px" w="100%">
              <FormControl id="email">
                <FormLabel fontSize="14px" fontWeight="500" color="white">
                  E-mail
                </FormLabel>
                <Input
                  borderColor={errors.email ? "#E1526C" : "white"}
                  rounded="10px"
                  py="25px"
                  border="2px solid #fff"
                  type="email"
                  color="#fff"
                  placeholder="Enter your email"
                  boxShadow="none" 
                  {...register("email")}
                  _placeholder={{
                    color: "#fff",
                    fontSize: { base: "16px", md: "14px" },
                  }}
                  _focus={{
                    borderColor: "white", 
                    boxShadow: "none", 
                  }}
                  _hover={{
                    borderColor: "#fff",
                  }}
                />
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.email && <p>{errors.email.message}</p>}
                </Box>
              </FormControl>

              <Box w="full">
                <Box display="flex" flexDirection="column" gap="10px">
                  <FormControl id="password" flex="1">
                    <FormLabel fontSize="14px" fontWeight="500" color="white">
                      Password
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        borderColor={errors.password ? "#E1526C" : "white"}
                        border="2px solid #fff"
                        rounded="10px"
                        color="#fff"
                        _focus={{
                          outline: "none",
                          ringColor: "white",
                          borderColor: "white", 
                          boxShadow: "none", 
                        }}
                        _hover={{
                          borderColor: "white",
                        }}
                        py="25px"
                        type={show ? "text" : "password"}
                        placeholder="Enter your Password"
                        {...register("password")}
                        _placeholder={{
                          color: "#fff",
                          fontSize: { base: "16px", md: "14px" },
                        }}
                      />
                      <InputRightElement>
                        <Box
                          size="sm"
                          rounded="10px"
                          pt={"4"}
                          pr={"2"}
                          onClick={handleClick}
                        >
                          {!show ? (
                            <AiOutlineEyeInvisible size={18} color="#fff" />
                          ) : (
                            <AiOutlineEye size={18} color="#fff" />
                          )}
                        </Box>
                      </InputRightElement>
                    </InputGroup>
                    <Box color="#E1526C" fontSize="14px" mt="5px">
                      {errors.password && <p>{errors.password.message}</p>}
                    </Box>
                  </FormControl>
                  <FormControl id="confirm_password">
                  <FormLabel fontSize="14px" fontWeight="500" color="white">
                      Confirm Password
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        borderColor={
                          errors.confirm_password ? "#E1526C" : "white"
                        }
                        _focus={{
                          outline: "none",
                          ringColor: "white",
                          borderColor: "white", 
                          boxShadow: "none", 
                        }}
                        _hover={{
                          borderColor: "white",
                        }}
                        py="26px"
                        border="2px solid #fff"
                        rounded="10px"
                        color="#fff"
                        type={show ? "text" : "password"}
                        placeholder="Confirm your Password"
                        {...register("confirm_password")}
                        _placeholder={{
                          color: "#fff",
                          fontSize: { base: "16px", md: "14px" },
                        }}
                      />
                      <InputRightElement>
                        <Box
                          size="sm"
                          rounded="10px"
                          pt={"4"}
                          pr={"2"}
                          onClick={handleClick}
                        >
                          {!show ? (
                            <AiOutlineEyeInvisible size={18} color="#fff" />
                          ) : (
                            <AiOutlineEye size={18} color="#fff" />
                          )}
                        </Box>
                      </InputRightElement>
                    </InputGroup>
                    <Box color="#E1526C" fontSize="14px" mt="5px">
                      {errors.confirm_password && (
                        <p>{errors.confirm_password.message}</p>
                      )}
                    </Box>
                  </FormControl>
                </Box>
              </Box>
            </VStack>
            <VStack
              w="100%"
              mt={{ base: "15px", md: "15px" }}
              direction="column"
              gap="15px"
            >
              <Button
                   bg={'#1448F5'}
                   color="white"
                   borderRadius="0"
                   rounded="8px"
                   w="100%"
                   py="25px"
                   fontSize="18px"
                   fontWeight="500"
                   type="submit"
                   isLoading={isLoading}
                   _hover={{
                     bg: '#0D3BC2', 
                   }}
              >
                Continue
              </Button>
              <Box color="white" fontWeight="400" fontSize={{base:'12px', md:'14px'}} >
                By creating an account, you agree to our
                <Link to="/" style={{ marginLeft: "5px" }}>
                  Privacy Policy
                </Link>
              </Box>
              <Box
                color="white"
                fontWeight="400"
                fontSize="14px"
                display="flex"
              >
                Already have an account?
                <Link to="/login" style={{ marginLeft: "5px" }}>
                  <Text _hover={{ color: "blue"}} textDecoration="underline" >Login</Text>
                </Link>
              </Box>
            </VStack>
          </VStack>
        </Stack>
    </Box>
  );
};

export default RegisterDetails;
