import { Avatar, Box, Button, Flex, Img, Text } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { USER_IMAGE_URL } from "../apiPaths";
import { NavLinks } from "../utils/contants";
import NavItem from "../Components/sharedComponent/NavItem";
import logo from "../assests/logo.png";
import { CloseIcon } from "@chakra-ui/icons";
import {
  FaSearch,
  FaList,
  FaBook,
  FaClipboardList,
  FaCalendar,
  FaCog,
  FaSignOutAlt,
} from "react-icons/fa";
const SidebarTwo = ({ handleToggle }) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const handleLogout = () => {
    window.localStorage.removeItem("currentUser");
    localStorage.removeItem("sessionRequestFormData");
    localStorage.removeItem("view");
    localStorage.removeItem("selectedButton");
    navigate("/");
  };

  const isTutor = currentUser?.roleName === "TUTOR";

  const studentNavLinks = [
    { path: "/tutor-search", title: "Tutor Search", icon: <FaSearch /> },
    { path: "/creativeslist", title: "Creatives List", icon: <FaList /> },
    { path: "/mybookings", title: "My Bookings", icon: <FaBook /> },
    { path: "/my-requests", title: "My Requests", icon: <FaClipboardList /> },
  ];

  const tutorNavLinks = [
    { path: "/calendar", title: "Calendar", icon: <FaCalendar /> },
    { path: "/yourservices", title: "My Services", icon: <FaList /> },
    { path: "/upcomingsessions", title: "My Bookings", icon: <FaBook /> },
    { path: "/my-requests", title: "My Requests", icon: <FaClipboardList /> },
    { path: "/ratesetting", title: "Settings", icon: <FaCog /> },
  ];

  const NavLinks = isTutor ? tutorNavLinks : studentNavLinks;

  return (
    <Box
      as="nav"
      pt={"1.5rem"}
      backgroundColor="#1D1D1D"
      overflowX="hidden"
      overflowY="auto"
      bgSize="cover"
      bgPosition="center"
      height={
        pathname === "/ratesetting" || pathname === "/calendar"
          ? " 100vh"
          : "100%"
      }
      justifyItems={"space-between "}
      px="20px"
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
      pb="2rem"
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"start"}
        justifyItems={"space-between"}
      >
        <Box display={"flex "} justifyContent={"space-between"} width={"100%"}>
          <Img src={logo} w={{ base: "40%", lg: "40%" }} />
          <div
            onClick={handleToggle}
            style={{ position: "relative", left: "1rem", cursor: "pointer" }}
          >
            <CloseIcon size={35} display={{ base: "block", lg: "none" }} />
          </div>
        </Box>
        <Flex
          mt="50px"
          justifyContent={"center"}
          alignItems="center"
          gap="15px"
        >
          <Button background="none" padding="0" as={Link} to="/profile">
            <Box>
              <Avatar
                width="69px"
                height="69px"
                name={currentUser?.displayName || "A"}
                src={
                  currentUser?.profile
                    ? USER_IMAGE_URL + currentUser?.profile
                    : "https://avatars2.githubusercontent.com/u/37842853?v=4"
                }
                cursor="pointer"
                crossOrigin="annonymous"
              />
            </Box>
          </Button>
          <Flex flexDirection="column">
            <Text
              fontWeight="500"
              fontSize="16px"
              color="white"
              lineHeight="18px"
            >
              {currentUser?.displayName
                ? currentUser?.displayName
                : "user name"}
            </Text>
            <Link to="/profile">
              <Text
                fontWeight="300"
                fontSize="16px"
                color="#0F36B8"
                _hover={{
                  textDecoration: "underline",
                }}
              >
                Edit
              </Text>
            </Link>
          </Flex>
        </Flex>
        <Box mt={"3rem"}>
          {NavLinks.map((item) => {
            return (
              <NavItem
                onClick={handleToggle}
                path={item.path}
                title={item.title}
                pathname={pathname}
                icon={item.icon}
              />
            );
          })}
        </Box>
      </Box>
      <Box>
        <Button
          bg="#0F36B8"
          rounded="8px"
          w="100%"
          py="1rem"
          border="1px solid  #2F2F2F"
          fontSize="16px"
          fontWeight="600"
          justifyContent="flex-start"
          alignItems="center"
          onClick={handleLogout}
          color="#fff"
          _hover={{
            boxShadow: "0px 4px 10px rgba(90, 90, 90, 0.3)",
            bg: '#0F36B8'
          }}
        >
          <span style={{ fontSize: "16px", marginRight: "12px" }}>
            <FaSignOutAlt />
          </span>
          Log out
        </Button>
      </Box>
    </Box>
  );
};

export default SidebarTwo;








  