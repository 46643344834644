import React, { useState, useEffect } from 'react';
import bgImage from '../assests/images/home.png';

import {
  Box,
  Flex,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Heading,
  Img,
  Text,
} from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assests/logo.png';

import CustomBtn from '../Components/sharedComponent/CustomBtn';

export default function Home() {
  const [userData, setUserData] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const pathname = location.pathname;
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && currentUser.roleName === 'TUTOR') {
      localStorage.setItem('selectedButton', 'calendar');
      navigate('/calendar');
    }
    if (currentUser && currentUser.roleName === 'STUDENT') {
      localStorage.setItem('selectedButton', 'dashboard');
      navigate('/tutor-search');
    }
    const element = document.querySelector('body');
    const input = document.querySelector('body .css-1ngklx');
    if (element) {
      element.style.backgroundColor = '#F2F2F2';
      element.style.color = 'black';
    }
  }, []);

  const handleSearchEnter = (event) => {
    if (event.key === "Enter" && searchQuery.trim()) {
      navigate(`/search/${searchQuery}`);
    }
  };

  return (
    <Box
      position="relative"
      background={`url(${bgImage})`}
      backgroundSize={{ base: 'cover', lg: 'cover' }}
      backgroundRepeat="no-repeat"
      backgroundPosition={{ base: 'right', lg: 'top' }}
      display={{ lg: 'flex' }}
      flexDirection={{ base: 'column-reverse', lg: 'column' }}
      height={{ base: '100vh', lg: '100vh' }}
      py={{ base: '1rem', lg: '.5rem' }}
      alignItems={'center'}
      overflowY={'hidden'}
    >
      {/* Color Overlay */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        background={{ base: "none", md: "rgba(0, 8, 56, 0.5)"}}
        zIndex={0}
      />

      <Flex
        justify="space-between"
        align="center"
        mx={{ base: 'auto' }}
        w={{ base: '90%', md: '90%' }}
        zIndex={1}
        mt={5}
      >
        <Box display="flex" alignItems="center">
          <Img
            src={logo}
            alt="Logo"
            h={{ base: '2rem', lg: '2.5rem' }}
            w={{ base: 'auto', lg: 'auto' }}
          />
        </Box>

        <Box display="flex" gap="10px" alignItems="center">
          {!currentUser && (
            <>
              <Box display={{ base: 'none', lg: 'block' }}>
                <Link to={`/register`}>
                  <CustomBtn title={'Sign Up'} isBg={false} pathname={pathname} />
                </Link>
              </Box>
              <Box display={{ base: 'none', lg: 'block' }}>
                <Link to={`/login`}>
                  <CustomBtn title={'Login'} isBg={true} pathname={pathname} />
                </Link>
              </Box>
            </>
          )}
          {currentUser && (
            <Button
              bg="white"
              _hover={{
                bg: 'black',
                color: 'white',
              }}
              color="blue"
              rounded="40px"
              py="10px"
              px="20px"
              mx="10px"
              border="1px solid  #fff"
              fontSize="14px"
              fontWeight="600"
              onClick={() => {
                window.localStorage.removeItem('currentUser');
                localStorage.removeItem('sessionRequestFormData');
                localStorage.removeItem('view');
                navigate('/login');
              }}
            >
              LOG OUT
            </Button>
          )}
        </Box>
      </Flex>

      <Box
        width={{ base: '100%', md: '90%' }}
        height={'80vh'}
        display={'flex'} 
        alignItems="center"  
        zIndex={1}               
      >
        <Box py="auto" color="#fff">
          <Heading fontSize={{ base: '17px', md: '23px' }} textAlign={{ base : "center", md: "left" }}  fontWeight="500" lineHeight="50px" >
            Start Your Creative Journey With Base
          </Heading>
          <Img src={logo} width="80%" margin={{ base : "auto", md: "0" }}>
          </Img>
          {/* Search Input */}
          <Box margin={{ base: "auto", md: "0" }} p="0" border={'none'}>
            <InputGroup
              borderRadius={5}
              size="sm"
              width="80%"
              border={'none'}
              mt="5"
              mx={{ base: "auto", md: "0" }}
            >
              <Input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleSearchEnter}
                // onChange={handleChange}
                background="#fff"
                padding="20px 8px"
                rounded={'5'}
                fontWeight={'500'}
                color="#000"
                border={'none'}
                sx={{
                  '::placeholder': {
                    color: '#000',
                  },
                }}
              />
              <InputRightElement
                pointerEvents="none"
                padding="20px"
                fontSize="15px"
                children={<Search2Icon color="blue" h={3} w={3} />}
              />
            </InputGroup>
          </Box>

          {/* For smaller screens */}
          <Box
            w={'80%'}
            display={{ base: 'block', lg: 'none' }}
            margin={"auto"}
            position="relative"
            zIndex={2} 
          >
            <Box textAlign={"center"} mt="12px" cursor={"pointer"}>
              <Link to={`/register`}>
                <CustomBtn title={'Sign Up'} pathname={pathname} isBg={true} />
              </Link>
              <Box marginLeft="5px" pt={4} display={"flex"} justifyContent={"center"}>
                Already a member? <Text _hover={{ color: 'blue' }} marginLeft={"5px"}><Link to="/login"><u>Login</u></Link></Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

