import { 
  signInWithPopup, 
  OAuthProvider, 
  GoogleAuthProvider,
  sendPasswordResetEmail
} from 'firebase/auth';
import { auth } from '../config/firebase';

export const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const {user} = await signInWithPopup(auth, provider);
    console.log(user);  
};