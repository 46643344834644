import React from 'react';
import { Navigate } from 'react-big-calendar';
import { Box, Button, Text, Img } from '@chakra-ui/react';
import { IoIosArrowBack } from 'react-icons/io';
import { IoChevronForwardOutline } from 'react-icons/io5';
import { USER_IMAGE_URL } from '../../apiPaths';
import profileImage from '../../assests/images/mypic.png';
import { color } from 'framer-motion';
import { useMediaQuery } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
const CustomToolbar = ({
  label,
  onNavigate,
  onView,
  view,
  profile,
  displayName,
  currentUser,
}) => {
  const image = USER_IMAGE_URL + '/' + profile;
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const [month, year] = label.split(' ');
  const goToBack = () => {
    onNavigate(Navigate.PREVIOUS);
  };

  const goToNext = () => {
    onNavigate(Navigate.NEXT);
  };

  const goToCurrent = () => {
    onNavigate(Navigate.TODAY);
  };

  const changeView = (view) => {
    onView(view);
  };

  return (
    <Box
      py={{ base: '2', lg: '5' }}
      px={isSmallScreen ? '14px' : '14'}
      m="0"
      display={'flex'}
      flexDirection={'co'}
      justifyContent="space-between"
      alignItems={'center'}
      background={{ base: '#0033FF', lg: 'black' }}
      color="white"
    >
      <Box display={'flex'} alignItems={'center'} gap="5">
        <Box
          display="flex"
          justifyContent="center"
          gap="15px"
          alignItems={'center'}
          justifyItems={'center'}
          minW={{ base: 'auto', md: 'auto' }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={isSmallScreen ? '2px' : '10px'}
          >
            <Text fontSize={{ base: '16px', md: '24px' }} fontWeight="500">
              {view === 'month'
                ? isSmallScreen
                  ? month.slice(0, 3)
                  : month
                : label}
            </Text>
            {
              <Text fontSize={{ base: '16px', md: '24px' }}>
                {view === 'month' ? year : ''}
              </Text>
            }
          </Box>
        </Box>
        <Box display={{ base: 'none', lg: 'block' }}>
          {view !== 'day' && (
            <Box
              display="flex"
              gap="10px"
              alignItems={'center'}
              cursor={'pointer'}
            >
              <Box onClick={goToBack}>
                <IoIosArrowBack size={20} />
              </Box>
              <Box onClick={goToNext} cursor={'pointer'}>
                <IoChevronForwardOutline size={20} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box display="flex" alignItems="center" gap="2">
        <Img
          width={isSmallScreen ? '50px' : '80px'}
          height={isSmallScreen ? '50px' : '80px'}
          rounded={'8px'}
          name={displayName}
          src={
            currentUser?.profile
              ? USER_IMAGE_URL + currentUser.profile
              : profileImage
          }
          cursor="pointer"
          crossOrigin="anonymous"
        />

        <Text
          fontWeight={{ base: '500', lg: '700' }}
          fontSize="18px"
          color="white"
          mx="10px"
        >
          {displayName}
        </Text>
      </Box>

      <Box>
        <Box display={{ base: 'block', lg: 'none' }}>
          <CloseIcon />
        </Box>
        <Box
          bg="#F2F2F2"
          rounded="10px"
          display={{ base: 'none', lg: 'block' }}
        >
          <Button
            variant="unstyled"
            onClick={() => changeView('month')}
            bgColor={view === 'month' ? '#0033FF' : 'white'}
            color={view === 'month' ? 'white' : 'black'}
            px={'30px'}
            rounded={'5px 1px 1px 5px'}
            fontSize={'1.2rem'}
          >
            Month
          </Button>
          <Button
            variant="unstyled"
            false
            onClick={() => changeView('week')}
            rounded={'1px 5px 5px 1px'}
            bgColor={view === 'week' ? '#0033FF' : 'white'}
            px={'40px'}
            color={view === 'week' ? 'white' : 'black'}
            fontSize={'1.2rem'}
          >
            Week
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomToolbar;
