import React, { useState, useEffect } from "react";
import MyBookingHeader from "../../../Headers/MyBookingHeader";
import MyBookingList from "./MyBookingList";
import EditBooking from "../../Shared/EditBooking";
import { Flex , Box, Text} from "@chakra-ui/react";
import { API_BASE_URL } from "../../../../apiPaths";
import AliceCarousel from "react-alice-carousel";


export default function MyBookings() {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [bookings, setBookings] = useState([]);
  const [isEditBooking, setIsEditBooking] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  function handleModifyBooking(id) {
    setIsEditBooking(true);
    setSelectedBookingId(id);

    const booking = bookings.find((booking) => booking._id === id);
    if (booking) {
      setSelectedBooking(booking); 
    }
  }

  function handleFormClose(val) {
    setIsEditBooking(val);
  }

  async function fetchData() {
    try {
      const response = await fetch(
        `${API_BASE_URL}/booking/mybookings/${currentUser?.id}`,
        {
          headers: {
            "x-access-token": currentUser.token,
          },
        }
      );
      const res = await response.json();
      setBookings(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

      const currentDate = new Date();

      // Filter for upcoming bookings
const upcomingBookings = bookings?.filter((booking) => {
  const startDate = new Date(booking?.sessionId?.start);
  const endDate = new Date(booking?.sessionId?.end);

  return (
    startDate > currentDate || // Bookings with start date in the future
    (startDate.toDateString() === currentDate.toDateString() &&
      endDate >= currentDate) // Bookings starting today that are still active
  );
});

// Create carousel items
const bookingItems =
  upcomingBookings?.length > 0
    ? upcomingBookings.map((booking) => (
        <Box key={booking._id} position={"relative"}>
          <MyBookingList
            data={booking}
            onModifyBooking={() => handleModifyBooking(booking._id)}
          />
        </Box>
      ))
    : [
        <Box
          key="empty-card"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgColor={"#fffA6"}
          flexDirection={"column"}
          rounded="8px"
          height="400px"
          p={{ base: 5, lg: 5 }}
          boxShadow={"md"}
          m={{ base: 2, lg: 1 }}
          color={"white"}
        >
          No Upcoming Bookings
        </Box>,
      ];

      const completedBookings =
        bookings?.length > 0
          ? bookings
              .filter((booking) => {
                const endDate = new Date(booking?.sessionId?.end);
                return endDate < currentDate;
              })
              .map((session) => (
                <Box key={session._id} position={"relative"}>
                  <MyBookingList
                    data={session}
                    onModifyBooking={() => handleModifyBooking(session._id)}
                  />
                </Box>
              ))
          : [
              <Box
                key="empty-card"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgColor={"#fffA6"}
                flexDirection={"column"}
                rounded="8px"
                height="400px"
                p={{ base: 5, lg: 5 }}
                boxShadow={"md"}
                m={{ base: 2, lg: 1 }}
                color={"white"}
              >
                No Bookings Available
              </Box>,
            ];


  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };
  return (
    <>
      <MyBookingHeader
        title="My Bookings"
        des="View and manage your upcoming bookings"
      />
      <Box width={"88%"} mx={"auto"} my={5} position={"relative"}>
      <Box mt={4}>
       <Text
          mt={"30px"}
          mb="20px"
          fontWeight="600"
          color={"white"}
          fontSize={{ base: "20px", md: "20px" }}
        >
          My Bookings
        </Text>
      <AliceCarousel
          mouseTracking
          items={bookingItems}
          responsive={responsive}
          controlsStrategy="alternate"
          infinite={false}
          disableDotsControls={true}
          renderPrevButton={() => (
            <button
              type="button"
              data-role="none"
              className="slick-arrow slick-prev"
              style={{ display: "block" }}
            >
              Previous
            </button>
          )}
          renderNextButton={() => (
            <button
              type="button"
              data-role="none"
              className="slick-arrow slick-next"
              style={{ display: "block" }}
            >
              Next
            </button>
          )}
        />
        </Box>
        </Box>
        <Box width={"88%"} mx={"auto"} my={5} position={"relative"}>
      <Box mt={4}>
       <Text
          mt={"30px"}
          mb="20px"
          fontWeight="600"
          color={"white"}
          fontSize={{ base: "20px", md: "20px" }}
        >
          Completed Bookings
        </Text>
      <AliceCarousel
          mouseTracking
          items={completedBookings}
          responsive={responsive}
          controlsStrategy="alternate"
          infinite={false}
          disableDotsControls={true}
          renderPrevButton={() => (
            <button
              type="button"
              data-role="none"
              className="slick-arrow slick-prev"
              style={{ display: "block" }}
            >
              Previous
            </button>
          )}
          renderNextButton={() => (
            <button
              type="button"
              data-role="none"
              className="slick-arrow slick-next"
              style={{ display: "block" }}
            >
              Next
            </button>
          )}
        />
        </Box>
        </Box>
        {isEditBooking && (
        <EditBooking onFormClose={handleFormClose} tutorId={selectedBooking.tutorId._id} selectedBooking={selectedBooking} fetchBookings={fetchData} bookingId={selectedBookingId} onDeleteSuccess={fetchData}/>
      )}
    </>
  );
}

