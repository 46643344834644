import React, { useState, useEffect } from "react";
import DashboardHeader from "../Components/Headers/DashboardHeader";
import { API_BASE_URL } from "../apiPaths";
import logo from '../assests/logo.png';
import CreativeItem from "../Components/FromSidebar/Student/CreativesList/CreativeItem";
import {
  Box,
  Flex,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputRightAddon,
  Heading,
  Image
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

export default function Dashboard() {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [userData, setUserData] = useState([]);

  async function fetchData(q) {
    try {
      const response = await fetch(`${API_BASE_URL}/user/tutors/get?q=${q}`, {
        headers: {
          "x-access-token": currentUser.token,
        },
      });
      const users = await response.json();
      setUserData(users.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // useEffect(() => {
  //   fetchData("");
  // }, []);

  const handleChange = (event) => {
    fetchData(event.target.value);
  };

  return (
    <Box>
      {/* <DashboardHeader /> */}
      <Flex
        mb={"20px"}
        flexDirection="column"
        mt={{ base: "20px", md: "40px" }}
        gap="24px"
        maxW={{ base: "90%", md: "85%" }}
        mx="auto">
        <Heading fontSize={{ base: "15px", md: "25px" }} fontWeight="400" mt="50px" textAlign="center">
          START YOUR CREATIVE JOURNEY WITH
        </Heading>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Image
            objectFit={"cover"}
            src={logo}
            alt="Logo"
          width="40%" 
          height="auto"
            backgroundSize="cover"
            crossOrigin="anonymous"
          />
        </Box>
        <InputGroup borderRadius={5} size="sm" width="50%" margin="auto">
          <Input
            type="text"
            placeholder="Search creative..."
            placeholdertextcolor="red"
            onChange={handleChange}
            border="1px solid black"
            borderWidth="2px"
            borderColor="gray.300"
            borderRadius="10px"
            padding="20px 20px"
            _hover={{
              borderWidth: "2px",
              borderColor: "gray.300",
            }}
            sx={{ "::placeholder": { color: "grey" } }}
          />
          <InputRightElement
            pointerEvents="none"
            padding="20px 20px"
            fontSize="18px"
            children={<Search2Icon color="#2998FF" />}
          />
        </InputGroup>

        {userData?.map((user) => (
          <CreativeItem key={user._id} data={user} />
        ))}
      </Flex>
    </Box>
  );
}
