import React from "react";
import moment from "moment";
import { Box, Text } from "@chakra-ui/react";

const MonthCustomHeader = ({ date }) => {
  const dayOfWeek = moment(date).format("ddd").toLowerCase();

  return (
    <Box display="flex" alignItems="center" mb="18px" borderBottom="none" m="0">
      <Text textTransform="capitalize" fontWeight="700" textAlign="center" m="auto" my="auto" fontSize="16px">
        {dayOfWeek}
      </Text>
    </Box>
  );
};

export default MonthCustomHeader;
