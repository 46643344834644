import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';

export default function CreativesListHeader() {
  return (
    <>
      <Box
        w="full"
        px="24px"
        bg={{ base: 'blue', md: '#000000' }}
        color="white"
        py={{ base: '1rem', md: '1.8rem' }}
      >
        <Flex alignItems="center" justifyContent="center" gap="5px">
          <Box>
            <Heading
              color="white"
              fontWeight="600"
              fontSize="2rem"
              textAlign="center"
            >
              Creatives
            </Heading>
            <Text color="white" fontWeight="500" fontSize="18px">
              Select a Creative to see their availability
            </Text>
          </Box>
          <Box></Box>
        </Flex>
      </Box>

      <Link>
        <Box
          display={{ base: 'block', md: 'none' }}
          w="full"
          px="24px"
          borderBottomWidth="1px"
          bg="dull_black"
          boxShadow="sm"
          color="white"
          py="6px"
        >
          <Flex cursor="pointer" alignItems="center" gap="2">
            <FaChevronLeft />
            Back
          </Flex>
        </Box>
      </Link>
    </>
  );
}
